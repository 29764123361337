import React from 'react'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axiosConfig from '../../axiosConfig'
import { useCookies } from 'react-cookie'



const ForgetPassword = ({query,setAuthToken,setisAuthenticate}) => {

    const [email , setEmail] = useState("")
    const [Errors ,setErrors] = useState({})

    const handleSubmit = e =>{
        setErrors({})
        e.preventDefault();
        axiosConfig.post('forget-password',{email:email})
            .then(res=>{
                setErrors({
                   'status' : res.data.status,
                   'message' : res.data.message
                })
            })
    }

    return (
        <div className='container'>
            <div class="fd-lo-fo-ban">
            <div className='fd-lo-fo-sub-ban'>
            <div class="d-grid justify-content-center">
            <img src={"image/fleekdealz-logo-2.png"} alt="" className="img-fluid fd-lo-fo-img" width="180px" />
            </div>
            <h2 className='mb-2'>
                Welcome
            </h2>
            {("message" in Errors)?
            <p className=''>{Errors.message}</p>
            : <p className=''>Enter your email and We'll sent a link to reset your password.</p>
            }
            <form className='' onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                    <input 
                        type="email" 
                        name='email'
                        className="form-control"
                        id="exampleInputEmail1" 
                        placeholder="Enter Email" 
                        aria-describedby="emailHelp"
                        value={email} 
                        onChange={(e)=>setEmail(e.target.value)} 
                    />
                    {/* <i class="fas fa-user-tie "></i> */}
                    <i class="fas fa-envelope fd-lo-fo-ic"></i>
                    {("email" in Errors)?
                    <span style={{ color: "red" }}>{Errors.email}</span>
                    :<></>
                    }
                    <div  class="form-text fr-tx-fp text-center end-0"><Link to="/login" class="fr-tx-fp"><small><i class="fas fa-arrow-left"></i> Back to Login</small></Link></div>

                </div>
                {(email) ?
                    <button type="submit" className="fd-lo-fo-ban-form-button btn btn-primary ">Sent Link</button>
                    : <button type="button" className="fd-lo-fo-ban-form-button btn btn-primary" disabled>Sent Link</button>
                }
            </form>
            </div>
        </div>
        </div>
    )
}

export default ForgetPassword