//axios config
import axiosConfig from '../../axiosConfig';

// library and packages
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import PersonalDetail from './MultiStepForm/PersonalDetail';
import PlaceOrder from './MultiStepForm/PlaceOrder';
import ShippingDetail from './MultiStepForm/ShippingDetail';

//Components
import StepWizard from './MultiStepForm/StepWizard';

import Cookies from 'universal-cookie';
import { useCookies } from 'react-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
const user = JSON.parse(localStorage.getItem('AuthUser'))
const initInfo = {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    isCreateAccount: false,
    BillingDetail: {
        Country: 'United States',
        Address1: user?.address_1 || '',
        Address2: user?.address_2 || '',
        City: user?.city || '',
        State: user?.state || '',
        Zip:  user?.postcode || ''

    },
    isShiptoDiffAdd: user?true:false,
    ShippingDetail: {
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        phone: user?.phone || '',
        Country: 'United States',
        Address1: user?.address_1 || '',
        Address2: user?.address_2 || '',
        City: user?.city || '',
        State: user?.state || '',
        Zip:  user?.postcode || ''

    },
    CardNumber: '',
    Expiry_dt: '',
    Cvv: ''
}

const CheckOut = (props) => {

    const {
        Cart ,
        CartProduct,
        SubTotal,
        ItemTotal,
        Itmes,
        ShippingCost,
        isAuthenticate,
        query,
        AuthToken
    } = props
    const {
        setCartProduct , 
        setCart,
        setAuthToken,
        setisAuthenticate,
        setSubTotal,
        setItemTotal,
        setItmes,
        setShippingCost
    } = props

    
    // const cookies = new Cookies();
    const [cookies,setCookie,removeCookie] = useCookies();
    const [Step, setStep] = useState(isAuthenticate?3:1)
    const [Alert, setAlert] = useState({
        code : 0,
        message : ""
    })
    const [IsLoading, setIsLoading] = useState(false)
    const AlertBox = useRef(null)
    const [UserDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('UserDetails'))||initInfo)
    const navigate = useNavigate();
    const handleAlert = (type) => {
        if(AlertBox && AlertBox.current){
            AlertBox.current.style.display = "none"
        }
        setAlert({ 
            code : 0,
            message : ""
        })
        if (type==="success") {
            removeCookie('fdcp',{
                path: `${process.env.REACT_APP_PUBLIC_URL}`,
            })
            window.location="/"
        }
    }

    const placeOrder = () => {
        if(AlertBox && AlertBox.current){
            AlertBox.current.style.display = "block"
        }
        var personal_details = {};
        var payment_details = {};
        personal_details['payment_method'] = "bacs"
        personal_details['payment_method_title'] = "Authorize.Net-Credit card"
        personal_details['set_paid'] = true
        const billingDetails = {

            first_name: UserDetails.first_name,
            last_name: UserDetails.last_name,
            address_1: UserDetails.BillingDetail.Address1,
            address_2: UserDetails.BillingDetail.Address2,
            city: UserDetails.BillingDetail.City,
            state: UserDetails.BillingDetail.State,
            postcode: UserDetails.BillingDetail.Zip,
            country: "United States",
            company : "",
            email:  UserDetails.email.toLowerCase(),
            phone: UserDetails.phone
        }

        personal_details['billing'] = billingDetails
        if (!UserDetails.isShiptoDiffAdd) {
            personal_details['shipping'] = billingDetails
        }
        else {
            personal_details['shipping'] = {
                first_name: UserDetails.ShippingDetail.first_name,
                last_name: UserDetails.ShippingDetail.last_name,
                address_1: UserDetails.ShippingDetail.Address1,
                address_2: UserDetails.ShippingDetail.Address2,
                city: UserDetails.ShippingDetail.City,
                state: UserDetails.ShippingDetail.State,
                postcode: UserDetails.ShippingDetail.Zip,
                company : "",
                country: "United States",
                email: UserDetails.ShippingDetail.email.toLowerCase(),
                phone: UserDetails.ShippingDetail.phone
            }
        }


        // console.log(itmes)
        personal_details['line_items'] = Itmes
        personal_details['shipping_lines'] = [{
            method_id: "1",
            method_title: "Free Shipping",
            total: `${parseFloat(ShippingCost).toFixed(2)}`
        }]
        // console.log()

        payment_details['cc_number'] = UserDetails.CardNumber;
        payment_details['expiry_month'] = UserDetails.Expiry_dt.split('/')[0];
        payment_details['expiry_year'] = UserDetails.Expiry_dt.split('/')[1];
        payment_details['cvv'] = UserDetails.Cvv;
        payment_details['amount'] = SubTotal;
        const body =  {
            personal_details: personal_details,
            payment_details: payment_details,
        }
        if (isAuthenticate) {
            body['token'] = AuthToken
        }
         
        axiosConfig.post('order',body)
            .then(res => { 
                setAlert({
                    code : res.data.status,
                    message : res.data.message,
                    isShow:true,
                    type:'success'
                })
                if(res.data.status===200)
                {
                    // console.log(res.data)
                    // setisAuthenticate(true)
                    if(res.data.token&&res.data.user){
                        
                        setCookie('fduat',res.data.token,{
                            path: `${process.env.REACT_APP_PUBLIC_URL}`,
                            expires : new Date(Date.now()+(30*86400000)),
                        })
                        setAuthToken(res.data.token)
                        localStorage.setItem('AuthUser',JSON.stringify(res.data.user))
                        localStorage.removeItem('UserDetails')
                        setisAuthenticate(true)
                         
                    }
                    setCart([])
                    setCartProduct([])
                }
            })
            .catch(err => {
                setAlert({
                    code : err.data.status,
                    message : err.response.data.message,
                    isShow:true,
                    type:'warning',
                })
            //    console.log(err.response.data.message)
            })
            .finally(()=>setIsLoading(false))
    }

    // console.log(UserDetails,Step)

    useEffect(() => {
        setStep(parseInt(query.get('step'))||(isAuthenticate?3:1))
    }, [query.get('step')])
    

    return (
        <>
            <Helmet>
                <title>Check-Out</title>
            </Helmet>
            
            <div className="fleekDeals-single-banner" style={{ 'marginTop': '7rem' }}>
           
                <div className="row mx-2">
                    {Alert.code!==0?
                    <SweetAlert 
                    type={Alert.type}
                    show={Alert.isShow} 
                    onConfirm={()=>handleAlert(Alert.type)}
                    > 
                            <p>
                            <b>Thank you for your business!</b><br/>
                            <span>{Alert.message}</span>
                            </p>
                    </SweetAlert>
                    :
                    <></>
                    }
                    {Alert.code === 0&&
                    <div className='col-md-7'>
                        <div>
                            <StepWizard
                                Step={Step}
                                setStep={setStep}
                                isAuthenticate={isAuthenticate}
                            />
                            <hr />
                            <div className='container mt-2'>
                                {Step === 1 &&
                                    <PersonalDetail
                                        UserDetails={UserDetails}
                                        Step={Step}
                                        setStep={setStep}
                                        setUserDetails={setUserDetails}
                                    />
                                }
                                {Step === 2 &&
                                    <ShippingDetail
                                        BillingDetail={UserDetails.BillingDetail}
                                        ShippingDetail={UserDetails.ShippingDetail}
                                        isShiptoDiffAdd={UserDetails.isShiptoDiffAdd}
                                        Step={Step}
                                        setStep={setStep}
                                        UserDetails={UserDetails}
                                        setUserDetails={setUserDetails}
                                    />
                                }
                                {Step === 3 &&
                                    <PlaceOrder
                                        UserDetails={UserDetails}
                                        Step={Step}
                                        setStep={setStep}
                                        setUserDetails={setUserDetails}
                                        placeOrder={placeOrder}
                                        IsLoading = {IsLoading}
                                        setIsLoading = {setIsLoading}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    }
                    {Alert.code!==0&&
                        <div className='col-12 col-md-4'>
                         <div className="card m-1">
                             <div className="card-body">
                                 <h6 className="card-subtitle text-secondary mb-2 text-muted">Personal Details</h6>
                                 <p className="card-text m-0">Name  :    <span className=''>{UserDetails.first_name} {UserDetails.last_name}</span></p>
                                 <p className="card-text m-0">Email :    <span className=''>{UserDetails.email}</span></p>
                                 {UserDetails.phone&&<p className="card-text m-0">Phone :    <span className='text-secondary'>{UserDetails.phone}</span></p>}
                             </div>
                         </div>
                         <div className="card m-1">
                             <div className="card-body">
                                 <h6 className="card-subtitle mb-2 text-muted">Billing Details</h6>
                                 <p className="card-text m-0">
                                     <span className='text-secondary'>{UserDetails.BillingDetail.Address1}{UserDetails.BillingDetail.Address2&&' , '+UserDetails.BillingDetail.Address2}</span>
                                     <br/>
                                     <span className='text-secondary'>{UserDetails.BillingDetail.City},{UserDetails.BillingDetail.State},{UserDetails.BillingDetail.Zip}</span>
                                     <br/>
                                     <span className='text-secondary'>{UserDetails.BillingDetail.Country}</span>
                                 </p>
                             </div>
                         </div>
                         <div className="card m-1">
                             <div className="card-body">
                                 <h6 className="card-subtitle mb-2 text-muted">Shipping Details</h6>
                                 {!UserDetails.isShiptoDiffAdd?
                                 <>
                                 <p className="card-text m-0">
                                     <span className='text-secondary'>{UserDetails.first_name} {UserDetails.last_name},</span>
                                     <br/>
                                     <span className='text-secondary'>{UserDetails.BillingDetail.Address1} {UserDetails.BillingDetail.Address2&&UserDetails.BillingDetail.Address2}</span>
                                     <br/>
                                     <span className='text-secondary'>{UserDetails.BillingDetail.City},{UserDetails.BillingDetail.State},{UserDetails.BillingDetail.Zip}</span>
                                     <br/>
                                     <span className='text-secondary'>{UserDetails.BillingDetail.Country}</span>
                                 </p>
                                 </>
                                 :
                                 <>
                                 <p className="card-text m-0">
                                     <span className='text-secondary'>{UserDetails.ShippingDetail.first_name} {UserDetails.ShippingDetail.last_name}</span>
                                     <br/>
                                     <span className='text-secondary'>{UserDetails.BillingDetail.Address1} {UserDetails.ShippingDetail.Address2&&UserDetails.ShippingDetail.Address2}</span>
                                     <br/>
                                     {UserDetails.ShippingDetail.Address2&&
                                     <>
                                     <span className='text-secondary'>{UserDetails.ShippingDetail.Address2}</span>
                                     <br/>
                                     </>}
                                     <span className='text-secondary'>{UserDetails.ShippingDetail.City},{UserDetails.ShippingDetail.State} ,{UserDetails.ShippingDetail.Zip}</span>
                                     <br/>
                                     <span className='text-secondary'>{UserDetails.ShippingDetail.Country}</span>
                                 </p>
                                 </>
                                 }
                             </div>
                         </div>
                     </div>
                    }
                    <div className={`${Alert.code===200?'col-md-8':'col-md-5'} border`}>
                    <div className="card border border-0">
                            <div className="card-body fd-summary-items-scroll">
                                <h5 className="card-title fd-sidebar-items-man-title">Your Items</h5>
                                <div className='row'>
                                    {CartProduct?.map((product, i)=>{
                                        console.log(product)
                                    const qanutity = Cart?.filter(e=>e.id===product.items._id.$oid&&e)[0]?.qty
                                    const price = product.items.sale_price && product.items.sale_price !== 0 ? parseFloat(product.items.sale_price).toFixed(2) : parseFloat(product.items.price).toFixed(2)
                                    const imageSlug = process.env.REACT_APP_API_URL + '/products/images/' + product.title + '/' + product.items.image_link[0].split('/').at(-2) + "_" + product.items.image_link[0].split('/').slice(-1)
                                    return (
                                    <>
                                        
                                        <div className='col-md-6 fd-summary-items-center-banner'>
                                        <div className=''>
                                        <LazyLoadImage src={imageSlug} alt="" className="img-fluid fd-summaru-items-image" /> 
                                        </div>
                                        <div className='fd-summary-items-banner'>
                                        <Link to={`/product/${product.items.SKU}/${product.items.slug}`} className="text-decoration-none text-black"><p>{product.items.title}</p></Link>
                                        {product.items.color &&<p className='df-summary-items-side'>{product.items.color}</p>}
                                        {/* {product.items.brand &&<p className='df-summary-items-side'>{product.items.brand}</p>} */}
                                        {/* {(product.items.shipping &&product.items.shipping>0)?<p className='df-summary-items-side'>+{parseFloat(product.items.shipping).toFixed(2)} Delivery</p>:<p className='df-summary-items-side'>Free Delivery</p>}                                          */}
                                        </div>
                                        </div>
                                        <div className='col-md-6'>
                                           <ul className='fd-summary-items-ul'>
                                            <li><p>${price}</p></li>
                                            <li><p>&times;</p></li>
                                            <li><p >{qanutity}</p></li>
                                            <li><p className='df-sm-it-ab'>${parseFloat(price*qanutity).toFixed(2)}</p></li>
                                        </ul>
                                        {(product.items.shipping &&product.items.shipping>0)?<p className='df-summary-items-side'>+{parseFloat(product.items.shipping).toFixed(2)} Delivery</p>:<p className='df-summary-items-side'>Free Delivery</p>} 
                                        </div>
                                        <hr/>
                                    </>
                                    )
                                    })
                                }
                                </div>
                            </div>
                            {/* <hr /> */}
                            {/* <div className='my-1'>
                                <div className='container'>
                                    <div>
                                        <button type='button' className='df-chackout-code-btn btn btn-outline-success w-100'>
                                            Add Promo Code
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr /> */}
                         </div>
                        



                        <div className="card border border-0">
                            <div className="card-body fd-sidebar-summary-body">
                                <h5 className="card-title fd-sidebar-summary-title">Summary</h5>
                                {/* <h6 className="card-subtitle mb-2 text-muted">Details</h6> */}
                                {/* <div className='row'> */}
                                    <div className=''>
                                        <p className="card-text fw-bold fd-sidebar-summary-title">Item SubTotal</p>
                                        <p className="card-text fd-sidbar-summary-dec">${parseFloat(ItemTotal).toFixed(2)}</p>
                                    </div>
                                    {/* <div className='col-md-6'>
                                        <p className="card-text">${parseFloat(ItemTotal).toFixed(2)}</p>
                                    </div>
                                </div> */}
                                {/* <div className='row'> */}
                                    <div className=''>
                                        <p className="card-text fw-bold fd-sidebar-summary-title"> Shipping Cost</p>
                                        <p className="card-text fd-sidbar-summary-dec">${parseFloat(ShippingCost).toFixed(2)}</p>
                                    </div>
                                    {/* <div className='col-md-6'>
                                        <p className="card-text">${parseFloat(ShippingCost).toFixed(2)}</p>
                                    </div>
                                </div> */}
                                {/* <div className='row'> */}
                                    <div className=''>
                                        <p className="card-text fw-bold fd-sidebar-summary-title">Est. Tax</p>
                                        <p className="card-text fd-sidbar-summary-dec">$0.00</p>
                                    </div>
                                    {/* <div className='col-md-6'>
                                        <p className="card-text">$0.00</p>
                                    </div>
                                </div> */}
                                <hr />
                                {/* <div className='row'> */}
                                    <div className='col-md-6'>
                                        <p className="card-text fw-bold fd-sidebar-summary-title">Total</p>
                                        <p className="card-text fd-sidbar-summary-dec">${SubTotal}</p>
                                    </div>
                                    {/* <div className='col-md-6'>
                                        <p className="card-text">${SubTotal}</p>
                                    </div>
                                </div> */}
                            </div>
                            <hr />
                            {/* <div className='my-1'>
                                <div className='container'>
                                    <div>
                                        <button type='button' className='df-chackout-code-btn btn btn-outline-success w-100'>
                                            Add Promo Code
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr /> */}
                            <div>
                                <p>
                                Your personal data will be used to process your order, 
                                support your experience throughout this website, and for other purposes described in our <Link to="/privacy-policy" className="fd-ti-li-po"><strong>privacy policy.</strong> </Link> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckOut

