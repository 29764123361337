import { Navigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

export const ProtectedRoute = ({ children , next }) => {
  // console.log(isAuthenticate)
  const cookies = new Cookies()
  const user = JSON.parse(localStorage.getItem('AuthUser'))
  const AuthToken = cookies.get('fduat')
  const {oid} = useParams();
  const nextAdd = oid?next+oid:next
  // console.log(user, AuthToken)
  if (!AuthToken||!user) {
    // user is not authenticated
    return <Navigate to={`/login?next=${nextAdd}`} />
  }
  return children;
};