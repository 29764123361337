import React from 'react'
import { Helmet } from 'react-helmet'

const TermAndCond = () => {
    return (
        <>
        <Helmet>
            <title>FleekDealz</title>
        </Helmet>
        <div className='container'>
        <div className='fd-tc-main-container mx-4'>
             <h3 className='text-center'> Terms of Use</h3>
             <hr />
            <h4>What personal data we collect and why we collect it</h4>
            <h4>Comments</h4>
            When visitors leave comments on our site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection. An anonymized string created from your email address (also called a hash) may be provided to the fleekdealz.com service to see if you are using it. After approval of your comment, your profile picture is visible to the public in the context of your comment.
            <h4>Media</h4>
            If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.
            <h4>Cookies</h4>
            If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year. If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser. When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed. If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
            <h4>Embedded content from other websites</h4>
            Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
            <h4>How long we retain your data</h4>
            If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue. For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.
            <h4>What rights you have over your data</h4>
            If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
            <h4>Where we send your data</h4>
            Visitor comments may be checked through an automated spam detection service.

            &nbsp;
            <h4><strong>GOOGLE PLAY ADDITIONAL LICENSE TERMS</strong></h4>
            If the App is provided to you through the Google Inc. (Google Inc. together with all of its affiliates, “Google”) Google Play, the following terms and conditions apply to you in addition to all the other terms and conditions of these Terms of Use:

            a) You acknowledge that Google is not responsible for providing support services for the App.

            b) If any of the terms and conditions in these Terms of Use are inconsistent or in conflict with Google’s Google Play Developer Distribution Agreement located at <a href="https://play.google.com/about/developer-distribution-agreement.html" target="_blank" rel="noopener">https://play.google.com/about/developer-distribution-agreement.html</a> (the “<strong>Google EULA Terms</strong>”) as of the Effective Date, the terms and conditions of Google EULA Terms will apply to the extent of such inconsistency or conflict.
            <h4><strong>YOUR AGREEMENT</strong></h4>
            YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS, UNDERSTAND THE TERMS, AND WILL BE BOUND BY THESE TERMS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS TOGETHER WITH THE PRIVACY POLICY REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.
        </div>
        </div>
        </>
    )
}

export default TermAndCond