//axios congigration
import axiosConfig from './axiosConfig'; 

// Components 
import Main from './Component/Main';
import Item from './Component/Item';
import AboutUs from './Component/AboutUs';
import ContactUs from './Component/ContactUs';
import Header from './Component/Header'
import Footer from './Component/Footer';
import TermAndCond from './Component/TermAndCond';
import PrivacyPolicy from './Component/PrivacyPolicy';
import CheckOut from './Component/Order/CheckOut';
import Login from './Component/Auth/Login';


// library and packages
import {
  useEffect,
  useState,
  useMemo
} from 'react'
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useCookies } from 'react-cookie';

//static files
import './App.css';
import ShippingRefund from './Component/ShippingRefund';
import { ProtectedRoute } from './Component/Routes/ProtectedRoute';
import OrdersList from './Component/User/OrdersList';
import Logout from './Component/Auth/Logout';
import ForgetPassword from './Component/Auth/ForgetPassword';
import ResetPassword from './Component/Auth/ResetPassword';
import Order from './Component/User/Order';
import OrderSubmit from './Component/OrderSubmit';
import Profile from './Component/User/Profile';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function App({ title }) { 

  const navigate = useNavigate();
  const [cookies,setCookie] = useCookies();

  //queryString Variables
  let query = useQuery();
  let { keyword } = useParams();
  let {pathname} = useLocation();
  let page = parseInt(query.get('page'));
  let seller = query.get('filter[seller]');
  let brand = query.get('filter[brand]');
  let price = query.get('filter[price]');
  let size = query.get('filter[size]');
  let category = query.get('filter[category]');
  let freeShipping = query.get('filter[freeShipping]');
  let onsale = query.get('filter[onSale]');
  // let step = query.get('filter[onSale]');
  const fdcp = cookies.fdcp || []
  const fduat = cookies.fduat || null

  /*******  Global Variables  *****/

  //state variables
  const [Loading, setLoading] = useState(true)
  const [Error, setError] = useState(null)

  
  const [Keyword, setKeyword] = useState([])
  const [search, setsearch] = useState([])
  const [Product, setProduct] = useState(null)
  const [isProduct, setisProduct] = useState(true)

  const [Cart, setCart] = useState(fdcp)
  const [CartProduct, setCartProduct] = useState([]) 

  const [AuthToken, setAuthToken] = useState(fduat)
  const [AuthUser, setAuthUser] = useState(JSON.parse(localStorage.getItem('AuthUser')))
  const [isAuthenticate, setisAuthenticate] = useState(AuthToken&&AuthUser)
  
  const [searchWord, setSearchWord] = useState(keyword ? unescape(keyword.replaceAll("+", "%20")) : '')
  const [Seletedkeyword, setSelectedkeyword] = useState()
  const [message, setMessage] = useState()
  const [ChildCategories, setChildCategories] = useState([
    'Skin Care' , 'Face' , 'Make up' , 'Perfeume'
  ])
  const [Pages, setPages] = useState({
    no_of_page: 5,
    current_page: page ? page : 1,
    total_products: 0
  })
  const [filter, setfilter] = useState({
    onsale: false,
    freeShipping: false,
    seller: [],
    brand: [],
    size: [],
    price: [],
    category : [],
  })

  //operational Variables

  const [Dummyfilter, setDummyfilter] = useState({
    onsale: false,
    freeShipping: false,
    seller: [],
    brand: [],
    size: [],
    price: [],
    category : [],
  })

  const [sort, setSort] = useState()
  const [appliedFilter, setappliedFilter] = useState({
    onsale: onsale ? true : false,
    freeShipping: freeShipping ? true : false,
    seller: seller ? seller.split(',') : [],
    size: size ? size.split(',') : [],
    brand: brand ? brand.split(',') : [],
    price: price ? price : '',
    category : category ? category.split(',') : [],
  })

  /*Cart Variables*/
  const [SubTotal, setSubTotal] = useState(0)
  const [ItemTotal, setItemTotal] = useState(0)
  const [Itmes, setItmes] = useState([])
  // const [Commission, setCommission] = useState(0)
  const [ShippingCost, setShippingCost] = useState(0)
  
  /******* functions ******/
  useEffect(() => {
    
   
    if (isAuthenticate) {
      axiosConfig.post('refresh',{
        token:AuthToken
      })
      .then(res=>{
        if (res.data.success) { 
          // console.log(res.data.token)
          setCookie('fduat',res.data.token,{
              path: `${process.env.REACT_APP_PUBLIC_URL}`,
              expires : new Date(Date.now()+(30*86400000)),
          })
          setisAuthenticate(true)
          setAuthToken(res.data.token)
          if(!res.data.token)
          {
            navigate('/login')
          }
        }
      })
      
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0,0)
  }, [pathname])

  const searchProduct = async (Search) => {
    Search = decodeURIComponent(escape(Search))
    // console.log(Search)
    //setSearchWord('')
    setSelectedkeyword('')
    setSearchWord(Search)
    setLoading(true)
    if (Search) {
      navigate(`/search/${Search.replaceAll(" ", "+")}`)
    }
    else {
      navigate('/')
    }
    await axiosConfig.post(`product`, {
      search: Search
    })
      .then(res => {
        if (res.data.success) {
          setProduct(res.data.data.product)
          setPages({
            no_of_page: res.data.data.lastpages,
            current_page: res.data.data.current_page,
            total_products: res.data.data.TotalProduct
          })
          setfilter({
            // ...filter,
            onsale: res.data.data.isOnsale,
            freeShipping: res.data.data.freeShipping,
            seller: res.data.data.filter[0].seller,
            brand: res.data.data.filter[0].brand,
            size: res.data.data.filter[0].size,
            category: res.data.data.category,
            price: res.data.data.price,
          })
          setDummyfilter({
            onsale: res.data.data.isOnsale,
            freeShipping: res.data.data.freeShipping,
            seller: res.data.data.filter[0].seller,
            brand: res.data.data.filter[0].brand,
            size: res.data.data.filter[0].size,
            category : res.data.data.category,
            price: res.data.data.price,
          })
          setisProduct(true)
          setError()

        } else {
          setError(res.data.message)
          setLoading(true)
          setisProduct(false)
        }
      })
      .catch(err => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
    await axiosConfig.post(`Keywords/Clk`, {
      keyword: Search
    })

   
    
    // setSearchWord('')
  }

  if (pathname.includes('login')) {
    return (
      <Routes>
            <Route exact path='/login' element={<Login query={query} setisAuthenticate={setisAuthenticate} setAuthToken={setAuthToken}/>} />
      </Routes>
    )
  }
  if (pathname.includes('forget-password')) {
    return (
      <Routes>
          <Route exact path='/forget-password' element={<ForgetPassword />} />
      </Routes>
    )
  }
  if (pathname.includes('reset-password')) {
    return (
      <Routes>
          <Route exact path='/reset-password/:token' element={<ResetPassword query={query} setisAuthenticate={setisAuthenticate} setAuthToken={setAuthToken}/>} />
      </Routes>
    )
  }


  return (

    <>
      <Header

        //Variables
        Seletedkeyword={Seletedkeyword}
        searchWord={searchWord}
        appliedFilter={appliedFilter}
        Keyword={Keyword}
        search={search}
        message={message}
        Pages={Pages}
        Cart={Cart}
        CartProduct={CartProduct}
        SubTotal={SubTotal}
        ItemTotal={ItemTotal}
        Itmes={Itmes}
        ShippingCost={ShippingCost}
        isAuthenticate={isAuthenticate}

        //functions
        setKeyword={setKeyword}
        setsearch={setsearch}
        setSearchWord={setSearchWord}
        SearchProduct={searchProduct}
        setappliedFilter={setappliedFilter}
        setPages={setPages}
        setLoading={setLoading}
        setSelectedkeyword={setSelectedkeyword}
        setCart={setCart}
        setCartProduct={setCartProduct}
        setSubTotal={setSubTotal}
        setItemTotal={setItemTotal}
        setItmes={setItmes}
        setShippingCost={setShippingCost}
      />
     
      <Routes>
        <Route path='/'
          element={
            <>
              <Main
                title={title} // [page title (react halmet)]

                //Variables
                Loading={Loading}
                Error={Error}
                isProduct={isProduct}
                Product={Product}
                search={search}
                Keyword={Keyword}
                keyword={keyword}
                appliedFilter={appliedFilter}
                Pages={Pages}
                searchWord={searchWord}
                filters={filter}
                Dummyfilter={Dummyfilter}
                sort={sort}
                Cart={Cart}
                CartProduct={CartProduct}

                //Funtions
                setLoading={setLoading}
                setError={setError}
                setisProduct={setisProduct}
                setSort={setSort}
                setDummyfilter={setDummyfilter}
                setfilter={setfilter}
                setProduct={setProduct}
                setPages={setPages}
                setKeyword={setKeyword}
                setsearch={setsearch}
                setSearchWord={setSearchWord}
                setappliedFilter={setappliedFilter}
                SearchProduct={searchProduct}
                setSelectedkeyword={setSelectedkeyword}
                setCart={setCart}
                setCartProduct={setCartProduct}
              />
            </>
          } />
        <Route exact path='product/:sku/:slug' element={<Item setCart={setCart} setCartProduct={setCartProduct} Cart = {Cart} CartProduct={CartProduct} />} />
        <Route exact 
              path='/check-out' 
              element={
                    <CheckOut 
                          Cart = {Cart} 
                          SubTotal={SubTotal}
                          ItemTotal={ItemTotal}
                          Itmes={Itmes}
                          ShippingCost={ShippingCost}
                          CartProduct={CartProduct} 
                          isAuthenticate={isAuthenticate}
                          setSubTotal={setSubTotal}
                          setItemTotal={setItemTotal}
                          setItmes={setItmes}
                          setShippingCost={setShippingCost}
                          setAuthToken={setAuthToken}
                          query={query}
                          AuthToken={AuthToken}
                          setisAuthenticate={setisAuthenticate}
                    />
              } 
        />
        <Route exact path='/terms-and-conditions' element={<TermAndCond />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/order-submit' element={<OrderSubmit />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/shipping-refund-policy' element={<ShippingRefund />} />
        <Route exact 
          path='/orders' 
          element={
            <ProtectedRoute next='/orders'>
              <OrdersList AuthToken={AuthToken} />
            </ProtectedRoute>
          } />
        <Route exact 
          path='/profile' 
          element={
            <ProtectedRoute next='/profile'>
              <Profile setAuthToken={setAuthToken} />
            </ProtectedRoute>
          } />
        <Route exact 
          path='/order/:oid' 
          element={
            <ProtectedRoute next={`/order/`}>
              <Order AuthToken={AuthToken} />
            </ProtectedRoute>
          } />
        <Route exact 
          path='/logout' 
          element={
            // <ProtectedRoute next='/logout'>
              <Logout setAuthToken={setAuthToken} isAuthenticate={isAuthenticate} setisAuthenticate={setisAuthenticate}/>
            // </ProtectedRoute>
          } />
      </Routes>
      <Footer />
    </>

  );
}

export default App;
