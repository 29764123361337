//axios congigration
import axiosConfig from '../axiosConfig';

import { useEffect ,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';


const SidebarCart = (props) => {

    const {
        Cart ,
        CartProduct,
        SubTotal,
        ItemTotal,
        Itmes,
        ShippingCost 
    } = props
    const {
        setCartProduct , 
        setCart,
        setSubTotal,
        setItemTotal,
        setItmes,
        setShippingCost
    } = props
    
    const navigate = useNavigate();
    // const cookies = new Cookies();
    const [cookies, setCookie, removeCookie] = useCookies(['fdcp']);
    // console.log(cookies.fdcp)

    useEffect(() => {
        const CalculateSub = () =>{
            // console.log( CartProduct?.length)
            if (Cart?.length !== 0 &&CartProduct?.length&& CartProduct?.length!==0) {
                var Total = 0
                var Weight = 0
                var shipping_cost = 0
                var seller
                var items = []
                for (let i = 0; i < CartProduct?.length; i++) {
                    seller = CartProduct[i].seller;
                    const product = CartProduct[i].items;
                    const cart = Cart?.filter(e => e.id === product._id.$oid && e)[0]
                    const price = product.sale_price ? product.sale_price : product.price
                    if (cart?.qty) {     
                        Total = Total + (price*cart.qty)
                        Weight = Weight + ((product.Packaging_Weight ? parseFloat(product.Packaging_Weight) : 0.9) * cart.qty)
                        
                        // console.log(product.shipping,shipping_cost)
                        
                        if(product.shipping){
                            if (cart?.qty>2) {
                                shipping_cost = shipping_cost + (product.shipping+(3*cart?.qty))
                            } else if (cart?.qty>1) {
                                shipping_cost = shipping_cost + (product.shipping+3)
                            }else{
                                shipping_cost = shipping_cost + (product.shipping*(cart?.qty))
                            }
                        }
                        // console.log(product.shipping,shipping_cost)
                        items[i] = {
                            product_id: product.SKU,
                            quantity: cart.qty
                        }
                    }
                }
    
                setItmes(items)
                // console.log(seller)
                
    
                setShippingCost(shipping_cost)
                setItemTotal(Total)
                // console.log(Total)
                let total = parseFloat(Total)+parseFloat(shipping_cost)
                setSubTotal(parseFloat(total).toFixed(2))
            }
        }

        CalculateSub()

        return () => {
            setItemTotal(0)
            setItmes([])
            setShippingCost(0)
            setSubTotal(0)
        }
    }, [Cart,CartProduct])

    useEffect(() => {
        const getCartProduct = async () => {
            await axiosConfig.post(`cart/product`,{data : JSON.stringify(Cart)})
                                .then(res=>{
                                    setCartProduct(res.data.data)
                                })
        }


        const manageCookies = () => {
            if (Cart?.length>CartProduct?.length) {
                // console.log(Cart?.length , 'Setnew')
                setCookie('fdcp',JSON.stringify(Cart),{
                    path: `${process.env.REACT_APP_PUBLIC_URL}`,
                    expires : new Date(Date.now()+86400000),
                })
            }
            else{ 
                if(cookies.fdcp)  
                {
                    // console.log(Cart?.length , "Remove")
                    removeCookie("fdcp",{
                        path: `${process.env.REACT_APP_PUBLIC_URL}`,
                    })
                }
                if (Cart?.length>0) {  
                      
                    // console.log(Cart?.length , "Remove and Add")
                    //console.log("HELLO form inner") 
                    setCookie('fdcp',JSON.stringify(Cart),{
                        path: `${process.env.REACT_APP_PUBLIC_URL}`,
                        expires : new Date(Date.now()+86400000),
                    }) 
                }
            }
        }

        manageCookies()
        if (Cart?.length!==0&&Cart?.length>CartProduct?.length) {
            getCartProduct()
        }
        else{
            setCartProduct(CartProduct)
            // CalculateSub()
        }
       
      return () => {
        setCartProduct([])
      }
    }, [Cart])
    


    // useEffect(() => {

    //     CalculateSub()
    
    //   return () => {
    //     setSubTotal(0)
    //   }
    // }, [CartProduct])
    
    // const CalculateSub = () => {
    //     if (Cart?.length!==0&&CartProduct?.length!==0) {
    //         let Total = 0
    //         // console.log(CartProduct)
    //         for (let i = 0; i < CartProduct?.length; i++) {
    //             const product = CartProduct[i].items;
    //             const cart = Cart?.filter(e=>e.id===product._id.$oid&&e)[0]
    //             // console.log(cart)
    //             Total =  Total + ((product.sale_price?parseFloat(product.sale_price):parseFloat(product.price))*cart?.qty)
    //         }
           
    //         setSubTotal(parseFloat(Total).toFixed(2))  
    //     }
    // }
    
    const HandleProductRemove = (product) => {

        setCart(Cart.filter(item => { return item.id !== product.items._id.$oid && item }))
        setCartProduct(CartProduct.filter(item=>{return item.items._id.$oid!==product.items._id.$oid&&item}))
    }

    // console.log(SubTotal)
    return (
        <div className='modal fade' id='CartSidebar'
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className='modal-dialog fd-cart-container'>
            <div className='modal-content fd-cart-modal-content'>
                <div className='modal-body'>
                    <div className="card">
                        <div className="card-header df-sidebar-header">
                            <div className='row'>
                                <div className='col-md-10 text-center pt-2'>
                                    <p className=' fs-3 fw-bold fd-sidecart-title'>
                                        Product Cart
                                    </p>
                                </div>
                                <div className='col-md-2 text-end'>
                                    <button type='button' className='df-add-cart-banner btn fs-3 fw-bold p-1' data-bs-dismiss="modal" aria-label="Close">
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='my-2'></div>
                        <div className='fd-cart-item-list'>
                        <ul className="list-group list-group-flush ">
                            {CartProduct?.length !== 0 && Cart?.length !== 0 ?
                                CartProduct?.map((product, i) => {
                                    const qanutity = Cart?.filter(e=>e.id===product.items._id.$oid&&e)[0]?.qty
                                    const imageSlug = process.env.REACT_APP_API_URL + '/products/images/' + product.title + '/' + product.items.image_link[0].split('/').at(-2) + "_" + product.items.image_link[0].split('/').slice(-1)
                                    return (
                                        <li className="list-group-item p-2 m-1" key={i}>
                                            <div className='row fd-cart-product'>
                                                <div className='col-2  fd-cart-product-img-wrapper'>
                                                    <img className='img-fluid fd-cart-product-img' src={imageSlug} />
                                                </div>
                                                <div className='col-4'>
                                                    <Link to={`product/${product.items.SKU}/p-${product.items.slug}`} className="text-decoration-none" aria-label="Close" data-bs-dismiss="modal">
                                                    <div className='fd-cart-product-title'>
                                                        <p>{product.items.title}</p>
                                                    </div>
                                                    </Link>

                                                    <>  
                                                        {product.items.size && <><p className='m-0'>Size: <span className=''>{product.items.size}</span></p></>}
                                                        {product.items.color && <><p className='m-0'>Color: <span className=''>{product.items.color}</span></p></>}
                                                    </>
                                                </div>
                                                <div className='col-3 fd-cart-btn-container px-0' >
                                                    <center>
                                                    <div className='btn-group fd-cart-btn-group'>
                                                        {qanutity === 1 ?
                                                            <button id="decrBtn" className='btn text-center text-danger fw-normal p-1' type='button' onClick={() => { HandleProductRemove(product) }} data-bs-dismiss = {Cart?.length<2&&'modal'}  ><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                            : <button id="decrBtn" className='btn text-center fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === product.items._id.$oid) { item.qty-- } return item })) }}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                        }
                                                        <p className='col-md-4 text-center fw-normal p-1 m-0'>{qanutity}</p>
                                                        <button id='incrBtn' className='btn text-center  fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === product.items._id.$oid) { item.qty++ } return item })) }}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                    </div>
                                                    </center>
                                                </div>
                                                <div className='col-3 px-0'>
                                                    <p className='text-center fs-6 fw-bold p-1 pb-0 m-0'>
                                                        ${product.items.sale_price && product.items.sale_price !== 0 ? parseFloat(product.items.sale_price * qanutity).toFixed(2) : parseFloat(product.items.price * qanutity).toFixed(2)}
                                                    </p>
                                                    {(product.items.shipping&&product.items.shipping>0) ? <><small className='text-center m-0'><span className=''>+${product.items.shipping} </span><span className='text-center m-0'>Delivery Cost</span></small></>:<><p className='text-center m-0'><span className='text-secondary'>Free Delivery</span></p></>}

                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                                :   
                                <div className="Container ">
                                    <p className=' fs-3 fw-normal p-1 text-center'> Your personal cart is empty
                                    </p>
                                    <p className=' fs-3 fw-normal p-1 text-center' data-bs-dismiss="modal" aria-label="Close">
                                       <Link to='/' className="df-sidebar-cart-link df-si-crt-li"> Find New Product</Link>
                                    </p>
                                </div>
                            }
                        </ul>
                        </div>

                    </div>
                </div>
                {Cart?.length!==0&&
                    <div className='fd-cart-footer'>
                        <div className='fd-cart-chout-btn-wrapper'>
                        <div className="card-body">
                                {/* <div className=''> */}
                                    <h5 className="card-title">Cart Summary</h5>
                                    <div className=''>
                                        <p className="card-text fw-bold fd-sidebar-summary-title">Item SubTotal</p>
                                        <p className="card-text fd-sidbar-summary-dec">${parseFloat(ItemTotal).toFixed(2)}</p>
                                    </div>
                                    {/* <div className='fd-sidbar-summary-dec'> */}
                                        {/* <p className="card-text">${parseFloat(ItemTotal).toFixed(2)}</p> */}
                                        {/* <p className="card-text">$7838.03</p>
                                    </div> */}
                                {/* </div> */}
                                {/* <div className=''> */}
                                    <div className=''>
                                        <p className="card-text fw-bold fd-sidebar-summary-title">Delivery Cost</p>
                                        <p className="card-text fd-sidbar-summary-dec">${parseFloat(ShippingCost).toFixed(2)}</p>
                                    </div>
                                    {/* <div className='fd-sidbar-summary-dec'>
                                        {/* <p className="card-text">${parseFloat(ShippingCost).toFixed(2)}</p> */}
                                        {/* <p className="card-text">$8888.88</p> */}
                                    {/* </div>  */}
                                {/* </div> */}
                                {/* <div className=''> */}
                                    {/* <div className=''>
                                        <p className="card-text fw-bold fd-sidebar-summary-title">Est. Tax</p>
                                        <p className="card-text fd-sidbar-summary-dec">$00.00</p>
                                    </div> */}
                                    {/* <div className='fd-sidbar-summary-dec'>
                                        <p className="card-text">$8888.88</p>
                                    </div> */}
                                {/* </div> */}
                                </div>
                                {/* <hr/> */}
                                <div className='row text-white'>
                                    <div className='col-8 col-md-9'>
                                    <button type='button' className='btn fd-cart-chout-btn' onClick={()=>{navigate('/check-out')}} data-bs-dismiss="modal" >
                                        <p className='m-0 p-0 fs-bold'>Go to Checkout</p>
                                        </button>
                                    </div>
                                   
                            
                                    <div className='col-4 col-md-3'>
                                        <div className='fd-cart-subtotal'>
                                            <p className='m-0 p-0'>${parseFloat(SubTotal).toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
    )
}

export default SidebarCart