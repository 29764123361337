import React from "react";
import Breakpoint from "./BreakPoint";

export default function Tablet(props) {
    // console.log("HELLO from tablet")
    return (
    <Breakpoint name="tablet">
    {props.children}
    </Breakpoint>
    );
}