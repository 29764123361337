import React from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

const OrderSubmit = () => {
  return (
    <>
            <Helmet>
                <title>FleekDealz</title>
            </Helmet>
            <div className="container pt-5">
                <div className="fd-or-sb-ban mt-5 " >
                    <div className="row">
                        <div className="col-md-6">
                            <div className="fd-or-sb-le-ban">
                                <img src="/image/order-submited.png" alt="Image Not Found" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fd-or-sb-ri-ban">
                                <p className="fd-or-sb-ri-tit "><i class="fal fa-check-circle"></i> Order Successfully Placed</p>
                                <p className="fd-or-sb-ri-dec mt-5">Your Order No : <span className="">QTR06587168916</span></p>
                                <p className="fd-or-sb-ri-dec">If You Questions About Your Order You Can Email Us at</p>
                                <p className="fd-or-sb-ri-dec"><span className=""> sales@fleekdealz.com</span> or Call as at <span className=""> +1 850-264-0287</span></p>
                                <button type="submit" className='fd-or-sb-ri-btn'>View Order Details </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
  )
}

export default OrderSubmit