import React from 'react'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
    return (
        <>
        <Helmet>
            <title>FleekDealz</title>
        </Helmet>
        <div className='container'>
        <div className='fd-tc-main-container mx-4'>
             <h3 className='text-center'> Privacy Policy</h3>
             <hr />
            <div>
                <h4>Introduction</h4>

                <p>
                    <strong>FleekDealz.com</strong> (“we” or “us”) values its visitors’ privacy. <br/>This privacy summarizes what information we might collect from a registered user or other visitor (“you”), and what we will and will not do with it. Please note that this privacy policy does not govern the collection and use of information by companies that <strong>FleekDealz.com</strong> does not control, nor by individuals not employed or managed by <strong>FleekDealz.com</strong>. <br/>If you visit a Web site that we mention or link to, be sure to review its privacy policy before providing the site with information.
                    What we do with your personally identifiable information
                    It is always up to you whether to disclose personally identifiable information to us, although if you elect not to do so, we reserve the right not to register you as a user or provide you with any products or services. <br/>“Personally identifiable information” 
                </p>
                <p>
                <hr/>
                <p>
                    means information that can be used to identify you as an individual, such as, <br />
                </p>
                for example:
                <p className='ps-3'>
                your name, company, email address, phone number, billing address, and shipping address
                your <strong>FleekDealz.com</strong><br/>User ID and password
                credit card information, except for information provided to third party payment processors
                any account-preference information you provide us 
                your computer’s domain name and IP address, indicating where your computer is located on the Internet<br/>
                session data for your login session, so that our computer can ‘talk’ to yours while you are logged in Any images or other data that you upload to our site while using features that you choose to use.<br/>
                If you do provide personally identifiable information to us we will:
                take commercially reasonable precautions to protect the information from loss, misuse and unauthorized access, disclosure, alteration and destruction; <br/>
                use the information, unless you opt out (see below), to provide you with information we believe you need to know or may find useful, such as (for example) modifications to the Terms of Service or news about our services and products or those being offered by a subsidiary, affiliate, joint venture partner, or business entity that acquires us or we acquire; and
                not, without your permission, use or disclose the information or sell or rent it to a third party except in one or more of the following ways:
                to provide services or products you have ordered, such as (for example) by providing it to a carrier who will be delivering the products you have ordered;
                in other ways described in this privacy policy or to which you have otherwise consented;
                in the aggregate with other information in such a way so that your identity cannot reasonably be determined (for example, statistical compilations);
                as required by law, for example, in response to a subpoena or search warrant;
                to outside auditors who have agreed to keep the information confidential;
                as necessary to enforce the Terms of Service;
                as necessary to protect the rights, safety, or property of <strong>FleekDealz.com</strong>, its users, or others; this may include (for example) exchanging information with other organizations for fraud protection and/or risk reduction; and
                to a subsidiary, affiliate, joint venture partner, or a successor organization in the event of a merger, acquisition, bankruptcy, or other sale or disposition of all or a portion of <strong>FleekDealz.com</strong>’ assets.
                </p>
                <h4>
                Other information we collect
                </h4>
                <p>      
                We may collect other information that cannot be readily used to identify you, such as (for example) the domain name and IP address of your computer. We may use this information, individually or in the aggregate, for technical administration of our Web site(s); research and development; customer- and account administration; and to help us focus our marketing efforts more precisely.
                </p>
                </p>

                <strong>Cookies</strong>

                <p>
                <strong>FleekDealz.com</strong>uses “cookies” to store personal data on your computer. We may also link information stored on your computer in cookies with personal data about specific individuals stored on our servers. If you set up your Web browser (for example, Internet Explorer or Firefox) so that cookies are not allowed, you might not be able to use some or all of the features of our Web site(s).
                </p>
                <br/>
                <h4>External data storage sites</h4>
                <p>
                We may store your data on servers provided by third party hosting vendors with whom we have contracted.<br/>
                <br/>
                Your privacy responsibilities<br/>
                <br/>
                To help protect your privacy, be sure:<br/>
                <br/>
                not to share your user ID or password with anyone else;<br/>
                to log off the <strong>FleekDealz.com</strong>Web site when you are finished;
                to take customary precautions to guard against “malware” (viruses, Trojan horses, bots, etc.), for example by installing and updating suitable anti-virus software.
                Notice to European Union users<br/>

                <strong>FleekDealz.com</strong>operations are located primarily in the United States. If you provide information to us, the information will be transferred out of the European Union (EU) to the United States. <br/>By providing personal information to us, you are consenting to its storage and use as described here in.
                </p>
                <h4>
                Information collected from children
                </h4>
                <p>
                You must be at least 13 years old to use <strong>FleekDealz.com</strong>’ Web site(s) and service(s). <strong>FleekDealz.com</strong> does not knowingly collect information from children under 13. (See the U.S. Children’s Online Privacy Protection Act.)
                </p>
                <h4>
                Changes to this privacy policy
                </h4>
                <p>
                We reserve the right to change this privacy policy as we deem necessary or appropriate because of legal compliance requirements or changes in our business practices. If you have provided us with an email address, we will endeavor to notify you, by email to that address, of any material change to how we will use personally identifiable information.
                </p>
                 <h4>
                 Questions, Comments, or to Opt-out?
                 </h4>
                <p>
                If you have questions or comments about <strong>FleekDealz.com</strong>’ privacy policy or would like to opt-out of receiving the information as described above, send an email to support@<strong>FleekDealz.com</strong> or contact us via any of the ways described in the About Us page at <strong>Fleekdealz.com/privacy-policy.</strong>
                </p>
            </div>
        </div>
        </div>
        </>
    )
}

export default PrivacyPolicy