import React from 'react'
import axiosConfig from '../axiosConfig'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const ProductCard = (props) => {
    const {Product , Cart , CartProduct ,Error , className , Item } = props
    const {setCart , setCartProduct} = props
    
    const AddToCart = async (ProductId) => {
        const product = {
            id : ProductId.$oid,
            qty : 1
        }
        setCart([...Cart,product])
        // await cookies.set('cart',{
        //     path: '/',
        //     expires : new Date(Date.now()+86400000),
        // })
        // console.log(cookies.get('cart'))
    }
    // console.log(Cart);
    const RemoveFromCart = async (ProductId) => {
        // setCart(Cart?.filter(item=>{return item !== Product &&item}))
        setCart(Cart?.filter(item=>{return item.id !== ProductId.$oid &&item}))
        setCartProduct(CartProduct.filter(item=>{return item.items._id.$oid!==ProductId.$oid&&item}))        
        // await cookies.set('cart','hello word',{
        //     path: '/',
        //     expires : new Date(Date.now()+86400000),
        // })
    }

    function countProductClick(e, fid, pid) {
        axiosConfig.post(`product/Clk`,{
            fid : fid.$oid,
            pid : pid.$oid
        })
    }
    
  return (
    Product?.map((item, i) => {
        const imageSlug =  process.env.REACT_APP_API_URL + '/products/images/'+item.title+'/'+item.items.image_link[0].split('/').at(-2)+"_"+item.items.image_link[0].split('/').at(-1)
        // console.log(item.items.Shipping_Method,item.items.shipping)
        return (
            <div key={i} className={className}>
                {item.items.qty>0?
                (!Cart?.some(e=>e.id===item.items._id.$oid)?
                    <div className={`${Error?'fd-error-atc-btn-wrapper':''} fd-atc-btn-wrapper ${Item?'fd-atc-item-btn':''}`}>
                        <button onClick={()=>AddToCart(item.items._id)} className='btn fd-atc-btn'><i className="fa fa-cart-plus" aria-hidden="true"></i></button>
                    </div>
                    :
                    <div className={`${Error&&'fd-error-atc-btn-wrapper'} fd-atc-btn-wrapper ${Item&&'fd-atc-item-btn'}`}>
                        <div className='fd-atc-btn'>
                        <button data-bs-toggle="modal" data-bs-target="#CartSidebar" className='btn text-white'><i className="fa fa-shopping-cart" aria-hidden="true"></i></button>
                        <span>|</span>
                        <button onClick={()=>RemoveFromCart(item.items._id)} className='btn text-white'><i className="fa fa-trash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                ):<></>
                }
                {/* <Link to={`/product/${item._id.$oid?item._id.$oid:item._id}/p-${item.items._id?.$oid}`} onClick={(e) => countProductClick(e, item._id, item.items._id)}  > */}
                <Link to={`/product/${item.items.SKU}/${item.items.slug}`} onClick={(e) => countProductClick(e, item._id, item.items._id)}  >
                    <div className="pdct-wrapper">
                        <div className="product-img">
                            <LazyLoadImage
                                src={imageSlug} 
                                onError={(e) => { 
                                            e.target.onerror = null; 
                                            e.target.src = '/image/image-not-available.png'
                                        }}
                                effect="blur" 
                                className="img-fluid fd-pr-img" />
                        </div>
                        <div className="product-cart">
                            <p className="pdct-caption">
                                {item.items.title ?
                                    <span title={item.items.title}>
                                        {item.items.title} {item.items.brand && `(${item.items.brand})`}
                                    </span>
                                    : <p style={{ color: "red" }}>This Product is not Available</p>
                                }
                            </p>
                            {/* <div className='d-flex' >
                                <div className="text-secondary fs-6">
                                    <i className="fa fa-star rating-color"></i>
                                    <i className="fa fa-star rating-color"></i>
                                    <i className="fa fa-star rating-color"></i>
                                    <i className="fa fa-star rating-color"></i>
                                    <i className="fa fa-star"></i>
                                </div>
                            </div> */}
                            <hr className="fd-pr-line" />
                            <div className="product-dec" >
                                {
                                    item.items.sale_price && item.items.sale_price !== 0 ?
                                        <>
                                        <span className="product-title">
                                            <b>${parseFloat(item.items.sale_price).toFixed(2)}</b>
                                            <span className="real-price">${parseFloat(item.items.price).toFixed(2)}</span>
                                            <span className="badge">
                                                {100 - Math.ceil((item.items.sale_price * 100) / item.items.price)}% off
                                            </span>
                                        </span>
                                        </>
                                        : <span className="product-title price">
                                            {item.items.price ?
                                                <>
                                                <b>${parseFloat(item.items.price).toFixed(2)}</b>
                                                {(item.items.MSRP&&item.items.MSRP!==0&&item.items.price<item.items.MSRP)&&
                                                    <>
                                                    <span className="real-price"> ${parseFloat(item.items.MSRP).toFixed(2)}</span>
                                                    <span className="fd-off-rate">
                                                        ({100 - Math.ceil((item.items.price * 100) / item.items.MSRP)}% off)
                                                    </span>
                                                    </>
                                                }   
                                                </>
                                                : <b>product is not avaiable</b>
                                            }
                                        </span>
                                }
                                
                                {!item.items.shipping||parseInt(item.items.shipping)  === 0 ?
                                    <p className="pdct-caption">Free Shipping</p>
                                    : <>
                                        <p className="pdct-caption">+${parseFloat(item.items.shipping).toFixed(2)} Delivery Cost</p>
                                    </>
                                }
                                {item?.items?.Shipping_Method!=='N\/A'?
                                    <p className="pdct-caption">{item.items.Shipping_Method}</p>
                                :<p className="pdct-caption">Others</p>
                                }
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    })
  )
}

export default ProductCard