import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const StepWizard = (props) => {
    const {Step , isAuthenticate ,setStep} = props
    const navigate = useNavigate()

    return (
        <div className='m-1 fd-st-dt-mi'>
            {!isAuthenticate&&<p className='px-4  fd-step-li-pg'>Already have an account ? <Link to={`/login?next=/check-out`}>Login</Link></p>}
            <div className='d-flex align-items-center fd-stepWizard p-2 px-4'>
                <div className='fd-stepWizard-step'>
                    <button 
                        className={`df-stepwizard-step btn rounded-circle ${Step>1?`btn-success`:`btn-outline-secondary`}  fd-stepWizard-btn`}
                        onClick={()=>{navigate('/check-out?step=1')}}
                        disabled={Step===1&&'on'}
                        >
                        1
                    </button>
                    {/* <small className='text-secondary text-center'>Personal Details</small> */}
                </div>
                <div className={Step>1?`fd-stepWizard-line-active`:`fd-stepWizard-line`}></div>
                <div className='fd-stepWizard-step'>
                    <button 
                        className={`df-stepwizard-step btn rounded-circle ${Step>2?`btn-success`:`btn-outline-secondary`}  fd-stepWizard-btn`}
                        onClick={()=>{navigate('/check-out?step=2')}}
                        disabled={Step<=2&&'on'}
                        >
                        2
                    </button>
                    {/* <small className='text-secondary text-center'>Shipping Details</small> */}
                </div>
                <div className={Step===3?`fd-stepWizard-line-active`:`fd-stepWizard-line`}></div>
                <div className='fd-stepWizard-step'>
                    <button 
                        className={`df-stepwizard-step btn rounded-circle ${Step===3?`btn-success`:`btn-outline-secondary`}  fd-stepWizard-btn`}
                        onClick={()=>{navigate('/check-out?step=3')}}
                        disabled={Step<=3&&'on'}
                        >
                        3
                    </button>
                    {/* <small className='text-secondary text-center'>Place Order</small> */}
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-between px-2'>
                <small className='text-success text-center'>Personal Details</small>
                <small className={`${Step>1?`text-success`:`text-secondary`} text-center`}>Shipping Details</small>
                <small className={`${Step===3?`text-success`:`text-secondary`} text-center`}>Place Order</small>
            </div>
        </div>
    )
}

export default StepWizard