//axios congigration
import axiosConfig from '../axiosConfig';
import axios from 'axios'

// Components 
import SidebarCart from './SidebarCart';

import Mobile from './Responsive_utilies/Mobile.js'
import Tablet from './Responsive_utilies/Tablet.js'
import Desktop from './Responsive_utilies/Desktop.js'

// import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react'


const Header = (props) => {


    const navigate = useNavigate();

    const {
        Seletedkeyword,
        searchWord,
        appliedFilter,
        Keyword,
        search,
        message,
        Pages,
        Cart,
        CartProduct,
        SubTotal,
        ItemTotal,
        Itmes,
        ShippingCost,
        isAuthenticate
    } = props

    const {
        setKeyword,
        setsearch,
        setSearchWord,
        SearchProduct,
        setappliedFilter,
        setPages,
        setLoading,
        setSelectedkeyword,
        setCart,
        setCartProduct,
        setSubTotal,
        setItemTotal,
        setItmes,
        setShippingCost
    } = props

    const [search_keyword, setsearch_keyword] = useState(searchWord ? searchWord : '')
    const [cursor, setCursor] = useState(-1)
    const searchResultRef = useRef(null)
    const searchInputRef = useRef(null)
    const [SuggestedProduct, setSuggestedProduct] = useState([])
    const [SearchRequest, setSearchRequest] = useState('')
    // const abortController = useRef()
    /******  Functions ******/
    useEffect(() => {
        if (searchWord) {
            setsearch_keyword(searchWord)
        } 
      return () => {
        setsearch_keyword('')
      }
    }, [searchWord])
    
    // console.log(search_keyword)
    const onChange = (e) => {
        // // console.log(e.target.value)
       
        if(!Seletedkeyword)
        {
            setSelectedkeyword('')
            const SearchWord = e.target.value
            if (SearchWord === "") {
                setsearch([])
                setSuggestedProduct([])
            }
            if (SearchWord.length > 2) {
                    const getKeyword = () => {
                        if(SearchRequest){
                            SearchRequest.cancel()
                        }
                        const ourRequest = axios.CancelToken.source()
                        setSearchRequest(ourRequest)

                        axiosConfig.post('Keywords', {
                            s: SearchWord.toLowerCase(),
                        },{cancelToken:ourRequest.token})
                            .then(res => {
                                if (res.data.success) {
                                    setSuggestedProduct(res.data.product?.slice(0,10))
                                    setsearch(res.data.keyword)
                                    setKeyword(res.data.keyword)
                                }
                            })
                    }
                    getKeyword()
                    setCursor(-1)
            }
            setsearch(search?.filter(a => { return a.length > search_keyword.length && a.toLowerCase().includes(search_keyword.toLowerCase()) && a }))
            setsearch_keyword(SearchWord);
    
            if (document.activeElement !== searchInputRef.current) {
                setsearch([])
                setSuggestedProduct([])
            }
        }
    }

    const scrollIntoView = pos => {
        searchResultRef.current.scrollTo({
            top: pos,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        if (cursor < 0 || cursor > search.length || !searchResultRef) {
            return () => { };
        }
        if(searchResultRef.current)
        {
            let listItems = Array.from(searchResultRef.current.children);
            listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop);
            if (document.activeElement !== searchInputRef.current) {
                setsearch([])
                setSuggestedProduct([])
            }
        }
    }, [cursor])

    const handleKeyboardNavigation = e => {
        // console.log(searchInputRef.current.value)
        // console.log(cursor )
        if (e.key === "ArrowDown") {
            // if (search.length !== 0) {
                setCursor(c => (c < SuggestedProduct.length - 1 ? c + 1 : c))
                // if (cursor !== search.length - 1) {
                //     setsearch_keyword(search[cursor + 1])
                //     setSelectedkeyword(search[cursor + 1])
                // }
            // }
        }
        else if (e.key === "ArrowUp") {
            setCursor(c => (c > 0 ? c - 1 : 0))
            // if (cursor !== 0) {
            //     setsearch_keyword(search[cursor - 1])
            //     setSelectedkeyword(search[cursor - 1])
            // }
        }
        else if (e.key === "Escape") {
            setsearch([])
            setsearch_keyword('')
            setCursor(-1)
        }
        else{
            setsearch([])
            setSelectedkeyword('')
            setCursor(-1)
        }

        // if (e.key === "Enter") {
        //     if (search_keyword!==""&&search[cursor]?.keyword) {
        //         // console.log(e.targe)
        //         setsearch([])
        //         setSelectedkeyword(search[cursor].keyword)
        //     }
        // }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setsearch_keyword('')
        setsearch([])
        setSearchWord(Seletedkeyword ? Seletedkeyword : search_keyword)
        SearchProduct(Seletedkeyword ? Seletedkeyword : search_keyword)
        // setSelectedkeyword()
        // setKeyword
    }

    const selectHandle = (e) => {

        setsearch_keyword('')
        setSearchWord(e.target.innerText)
        setSelectedkeyword(e.target.innerText)
        setsearch([])
        SearchProduct(e.target.innerText)
        // handleSubmit(e)
    }

    // console.log(search)
    return (
        <div>
            <div className="fd-header-banner">
                <div className="row gx-0 d-flex align-items-center">
                    <div className="col-md-3 d-md-flex justify-content-md-around">

                        <Link to="/" className="logo">
                            {/* <div className="fd-sidebar-link"><Link to={''} className="fd-sidebar-link" ><i className="fa fa-list-ul df-fa-icon" aria-hidden="true" ></i> </Link></div> */}
                            <Desktop>
                            <img src={"image/fleekdealz-logo-2.png"} alt="" className="img-fluid" />
                            </Desktop>
                            <Tablet>
                            <img src={"image/fleekdealz-logo-2.png"} alt="" className="img-fluid" />
                            </Tablet>
                            <Mobile>
                            <img src={"image/fleekdealz-logo-2.png"} alt="" className="img-fluid" />
                            </Mobile>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="">
                                <div className="search-box" >
                                    {/* <input type="search" name="searchbar" onKeyDown={e => handleKeyboardNavigation(e)} onChange={onChange} className="search-input" placeholder="Search Product..." value={Seletedkeyword ? Seletedkeyword : search_keyword} autoComplete="off" ref={searchInputRef} onBlur={() => setsearch([])} /> */}
                                    {/*  */}
                                    <input type="search" name="searchbar" onKeyDown={e => handleKeyboardNavigation(e)} onChange={onChange} className="search-input" placeholder="Search Product..." value={search_keyword} autoComplete="off" ref={searchInputRef}  onBlur={() => {setsearch([]);setSuggestedProduct([]);}}/>
                                    <button type='submit' className='fd-search-icon'> <i className="far fa-search"></i></button>
                                    {(search.length != 0 || SuggestedProduct?.length>0) && (
                                        <div className='DataResult result-box' ref={searchResultRef}>
                                            {SuggestedProduct?.length>0&&(
                                                <>
                                                {
                                                SuggestedProduct?.map((product,key) => {
                                                        const thumbnail = process.env.REACT_APP_API_URL+'/products/images/'+product.feed_title+'/thumbnail_'+product.image_link[0].split('/').at(-2)+"_"+product.image_link[0].split('/').at(-1)
                                                        
                                                        return (
                                                            <div key={key} className={`m-0 ${cursor === key ? "active-result" : ""}`} onMouseDown={()=>{navigate(`/product/${product.SKU}/${product.slug}`)}}>
                                                                <Link to='#' className="d-flex align-items-center mx-2 text-decoration-none">
                                                                <div>
                                                                    <img className="img-fluid fd-thumb-img p-1" src={thumbnail} />
                                                                </div>
                                                                <div>
                                                                <p className='ps-1 m-0 fs-6'>{product.title}</p>
                                                                <p className='ps-1 m-0 fs-6'>Price: ${product.sale_price?product.sale_price:product.price}</p>
                                                                </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <hr/>
                                                </>
                                                )
                                            }
                                            {
                                                // .filter(a => { return a.keyword.length > search_keyword.length && a.keyword.toLowerCase().includes(search_keyword.toLowerCase()) && a })
                                                search?.slice(0,9).map((keyword, idx) => {
                                                        return (
                                                            <div key={idx} className={cursor === idx ? "active-result" : ""}>
                                                                <Link to={''} onMouseDown={selectHandle} style={{textTransform:'capitalize',textDecoration:"none"}}>{keyword}</Link>
                                                            </div>
                                                        )
                                                    })
                                            }
                                            
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-3 d-md-flex d-flex justify-content-end align-content-center">
                        <button className='btn fs-3 fd-cart-label me-3'  data-bs-toggle="modal" data-bs-target="#CartSidebar">
                            <i className="far fa-shopping-bag m-1"></i>
                            <span className='fd-shopping-count fd-cart-qty' >{Cart?.length}</span>
                        </button>
                        {isAuthenticate?
                            <>
                                <button className="btn p-0 me-4 fs-2 fd-user-i" id="User" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* <img src={'image/profile.jpg'} alt="" className="img-fluid"/> */}
                                <i className="fa fa-user-circle" aria-hidden="true"></i>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="User">
                                    <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                                    <li><Link className="dropdown-item" to="/orders">Orders</Link></li>
                                    <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                                </ul>
                            </>
                        : 
                        <button className='btn fd-login me-5' onClick={()=>navigate('/login')}>
                            <Mobile>
                             <span className='m-1 fd-shopping-count fd-in-log-ban p-2' ><i className="fad fa-user-lock"></i></span>
                            </Mobile>
                            <Desktop>
                            <span className='m-1 fd-shopping-count fd-log-ban p-2' >Login</span>
                            </Desktop>
                            <Tablet>
                            <span className='m-1 fd-shopping-count fd-log-ban p-2' >Login</span>
                            </Tablet>
                        </button>
                        }
                        
                    </div>
                </div>
               
            </div>
           <SidebarCart 
            Cart = {Cart}
            setCart = {setCart}
            CartProduct = {CartProduct}
            setCartProduct = {setCartProduct}
            SubTotal={SubTotal}
            ItemTotal={ItemTotal}
            Itmes={Itmes}
            ShippingCost={ShippingCost}
            setSubTotal={setSubTotal}
            setItemTotal={setItemTotal}
            setItmes={setItmes}
            setShippingCost={setShippingCost}
           />
           
        </div>
    )
}

export default Header

// consumer : ck_8cf4f1e57f9dae431bbd50c0f9d9ee6fb97c44c0
// consumer key : cs_50dbb6ce3da4f2630eedc762741559cc6ce03f9f