import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import axiosConfig from '../../axiosConfig'

const Order = ({ AuthToken }) => {

    const { oid } = useParams()
    const [order, setOrder] = useState([])
    const [SubTotal, setSubTotal] = useState(0)
    const [Qty, setQty] = useState(0)
    useEffect(() => {
        axiosConfig.get(`/user/order/${oid}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + AuthToken
            }
        })
        .then(res => {
            console.log(res.data[0])
            setOrder(res.data[0])
        })
        .catch(err => { console.log(err.response.data) })

    }, [])
    useEffect(() => {
      if(order.length>0)
      { 
        var sum = 0 
        var qty = 0
        order[0]?.line_items?.map((product)=>{   
            console.log(product)
            sum = sum+parseFloat(product.subtotal)
            qty = qty+parseInt(product.quantity)
        })
        setSubTotal(sum)
        setQty(qty)
      }
    }, [order])
    
    return (
        <>
            {order.length>0?
                <>
                    <Helmet>
                <title>Order | fleekDealz</title>
                <meta name="description" content="fleekDeals" />
                <meta name="theme-color" content="#E6E6FA" />
            </Helmet>
            <div>
                <div className="mt-md-5 mx-md-5 mx-3 fd-container ">
                    <div class="card">
                            <div class="card-body row">
                                <div className='d-flex justify-content-between'> 
                                    <div>
                                        <h4 class="card-title">Order id #{order[0]?.id}</h4>
                                        <h5 class="card-title">{order[0]?.billing.first_name+' '+order[0]?.billing.last_name}</h5>
                                    </div>
                                    <div className='me-2 '>
                                        <p class="rounded-pill fs-6 p-2" style={{'background': 'rgb(99 207 188)','color': '#fff'}}>{order[0]?.status}</p>
                                    </div>
                                </div>
                                <div class="row">
                                <div class="card-body col-md-4">
                                <h6 className="card-subtitle mb-2 text-muted">Billing Details</h6>
                                 <p className="card-text m-0">
                                     <span className='text-secondary'>{order[0]?.billing.address_1}{order[0]?.billing.address_2&&' , '+order[0]?.billing.address_2}</span>
                                     <br/>
                                     <span className='text-secondary'>{order[0]?.billing.city},{order[0]?.billing.state},{order[0]?.billing.Zip}</span>
                                     <br/>
                                     <span className='text-secondary'>{order[0]?.billing.country}</span>
                                 </p>
                                </div>
                                <div class="card-body col-md-4">
                                <h6 className="card-subtitle mb-2 text-muted">Shipping Details</h6>
                                 <p className="card-text m-0">
                                     <span className='text-muted'>{order[0]?.shipping.first_name+" "+order[0]?.shipping.first_name}</span>
                                     <span className='text-secondary'>{order[0]?.shipping.phone}</span>
                                     <br/>
                                     <span className='text-secondary'>{order[0]?.shipping.address_1}{order[0]?.shipping.address_2&&' , '+order[0]?.shipping.address_2}</span>
                                     <br/>
                                     <span className='text-secondary'>{order[0]?.shipping.city},{order[0]?.shipping.state},{order[0]?.shipping.Zip}</span>
                                     <br/>
                                     <span className='text-secondary'>{order[0]?.shipping.country}</span>
                                 </p>
                                </div>

                                <div class="card-body col-md-4">
                                <h6 className="card-subtitle mb-2 text-muted">Payment Details</h6>
                                <p><strong>{order[0]?.payment_status}</strong></p>
                                {/* {order[0]?.payment&& */}
                                 <p className="card-text m-0">
                                     {/* <span className='text-muted'>{order[0]?.shipping.first_name+" "+ord}</span> */}
                                     <span className='text-secondary'>SubTotal&nbsp;:&nbsp;{parseFloat(SubTotal).toFixed(2).toString()+' '+order[0]?.currency}</span>
                                     <br/>
                                     <span className='text-secondary'>ShippingCost&nbsp;:&nbsp;{order[0]?.shipping_total+' '+order[0]?.currency}</span>
                                     <br/>
                                     <span className='text-secondary'>Total Amount&nbsp;:&nbsp;{order[0]?.total+' '+order[0]?.currency}</span>
                                     <br/>
                                     {/* <span className='text-secondary'>Status : {order[0]?.payment[0][0].payment_status}</span> */}
                                 </p>
                                {/* } */}
                                </div>
                                </div>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="card-body row">
                                    <div class="card-text col-7">
                                        <p class="card-text">
                                            Product
                                        </p>
                                        </div>
                                        <div class="card-text col">
                                        <p class="card-text">
                                            Quantity
                                        </p>
                                        </div>
                                        <div class="card-text col">
                                        <p class="card-text">
                                            Price
                                        </p>
                                        </div>
                                        <div class="card-text col">
                                        <p class="card-text">
                                            Amount
                                        </p>
                                        </div>
                                    </div>
                                    
                                </li>
                               {order[0]?.line_items?.map((product)=>{
                                // const item = order[0]?.line_items.filter((item)=>{
                                //     // setQty(Qty+item.quantity)
                                //     if (item.product_id===product.wp_id) {
                                //         return item.quantity
                                //     }
                                // })[0]
                                // setSum(Sum+parseFloat(product.price).toFixed(2))
                                return (
                                <li class="list-group-item">
                                    <div class="card-body row">
                                        <img src={product.image.src} class="col-md-1" alt="..."/>
                                        <div class="card-text col-md-6">
                                        <p class="card-text">
                                            {product.name}
                                        </p>
                                        </div>
                                        <div class="card-text mt-1 col">
                                        <p class="card-text">
                                            {product.quantity}
                                        </p>
                                        </div>
                                        <div class="card-text mt-1 col">
                                        <p class="card-text">
                                            {parseFloat(product.price).toFixed(2)} USD
                                        </p>
                                        </div>
                                        <div class="card-text mt-1 col">
                                        <p class="card-text">
                                            {parseFloat(product.subtotal).toFixed(2)} USD
                                        </p>
                                        </div>
                                    </div>
                                    </li>
                                )
                               })}
                               <li class="list-group-item">
                                <div class="card-body row">
                                    <div class="card-text col-md-6 col">
                                        <p class="card-text text-end me-4">
                                           SubTotal 
                                        </p>
                                    </div>
                                    <div class="card-text col-md-2 text-center col">
                                        <p class="card-text  ps-md-5">
                                           {Qty} 
                                        </p>
                                    </div>
                                    <div class="card-text col-md-2 text-center col">
                                        <p class="card-text ">
                                           {parseFloat(SubTotal).toFixed(2)} USD
                                        </p>
                                    </div>
                                    <div class="card-text col-md-2 text-center col">
                                        <p class="card-text ">
                                           {order[0]?.total} USD
                                        </p>
                                    </div>
                                </div>
                               </li>
                            </ul>
                    </div>
                </div>
            </div>
                </>
            :<div className='fleekDeals-single-banner'>
            <div className='container my-5'>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border m-5 text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
            }
        </>
    )
}

export default Order