import { useState } from "react"
import React from 'react'
import { useNavigate } from "react-router-dom"
// import { City, State } from "country-state-city"
// import Select from 'react-select';

const ShippingDetail = (props) => {

    const { BillingDetail, ShippingDetail, isShiptoDiffAdd } = props

    const { setUserDetails, UserDetails } = props
    const { setStep, Step } = props

    // const States =  State.getAllStates().filter((state)=>state.countryCode=="US")
    // const Cities = City.getCitiesOfCountry('US').map(city=>{
    //     return {
    //         value : city.name,
    //         label : city.name,
    //     }
    // })
    // const Cities = [
    //     {value:"FL",label:"FL"},
    //     {value:"FL",label:"FL"},
    //     {value:"FL",label:"FL"},
    //     {value:"FL",label:"FL"},
    //     {value:"FL",label:"FL"},
    //     {value:"FL",label:"FL"},
    //     {value:"FL",label:"FL"},
    //     {value:"FL",label:"FL"},
    // ]
    // console.log(Cities)
    // const filterCities = inputValue =>{
    //     return Cities.filter((i) =>
    //         i.value.toLowerCase().includes(inputValue.toLowerCase())
    //     );
    // }
   
    const [Errors, setErrors] = useState({})
    const navigate = useNavigate();

    const handleValidation = () => {
        let isFormValid = true;
        var error = {}
        if (!BillingDetail.Address1 || !BillingDetail.Address1 === 'undefined') {
            isFormValid = false;
            error['Address1'] = "Required!"
        }
        if (BillingDetail.Address1) {
            if (BillingDetail.Address1.length > 60) {
                isFormValid = false;
                error['Address1'] = "Invalid!* max:60"
            }
        }
        if (BillingDetail.Address2) {
            if (BillingDetail.Address2.length > 60) {
                isFormValid = false;
                error['Address2'] = "Invalid! max:60"
            }
        }

        if (!BillingDetail.City || !BillingDetail.City === 'undefined') {
            isFormValid = false;
            error['City'] = "Required!"
        }
        if (BillingDetail.City) {
            if (BillingDetail.City.length > 15) {
                isFormValid = false;
                error['City'] = "Invalid! max:15"
            }
        }
        if (!BillingDetail.State || !BillingDetail.State === 'undefined') {
            isFormValid = false;
            error['State'] = "Required!"
        }
        if (BillingDetail.State) {
            if (BillingDetail.State.length > 15) {
                isFormValid = false;
                error['State'] = "Invalid! max:15"
            }
        }
        if (!BillingDetail.Zip || !BillingDetail.Zip === 'undefined') {
            isFormValid = false;
            error['Zip'] = "Required!"
        }
        if (BillingDetail.Zip) {
            if (BillingDetail.Zip.length > 12) {
                isFormValid = false;
                error['Zip'] = "Invalid!"
            }
        }
        if (isShiptoDiffAdd) {
            if (!ShippingDetail.first_name || ShippingDetail.first_name === 'undefined') {
                isFormValid = false;
                error['ShippingDetail_first_name'] = "Required!"
            }
            if (!ShippingDetail.last_name || ShippingDetail.last_name === 'undefined') {
                isFormValid = false;
                error['ShippingDetail_last_name'] = "Required!"
            }
            if (!ShippingDetail.email || ShippingDetail.email === 'undefined') {
                isFormValid = false;
                error['ShippingDetail_email'] = "Required!"
            }
            if (ShippingDetail.email) {
                if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(ShippingDetail.email))) {
                    isFormValid = false;
                    error['ShippingDetail_email'] = "Invalid!"
                }
            }
            if (ShippingDetail.phone) {
                if (ShippingDetail.phone.replace(' ', '').replace('-', '').replace('+', '').replace('(', '').replace(')', '').length < 10) {
                    isFormValid = false;
                    error['ShippingDetail_phone'] = "invalid! min 10 digits"
                }
                if (ShippingDetail.phone.replace(' ', '').replace('-', '').replace('+', '').replace('(', '').replace(')', '').length > 13) {
                    isFormValid = false;
                    error['ShippingDetail_phone'] = "invalid! max 13 digits"
                }
            }
            if (!ShippingDetail.Address1 || !ShippingDetail.Address1 === 'undefined') {
                isFormValid = false;
                error['ShippingDetail_Address1'] = "Required!"
            }
            if (ShippingDetail.Address1) {
                if (ShippingDetail.Address1.length > 60) {
                    isFormValid = false;
                    error['ShippingDetail_Address1'] = "Invalid! max:60"
                }
            }
            if (ShippingDetail.Address2) {
                if (ShippingDetail.Address2.length > 60) {
                    isFormValid = false;
                    error['ShippingDetail_Address2'] = "Invalid! max:60"
                }
            }

            if (!ShippingDetail.City || !ShippingDetail.City === 'undefined') {
                isFormValid = false;
                error['ShippingDetail_City'] = "Required!"
            }
            if (ShippingDetail.City) {
                if (ShippingDetail.City.length > 15) {
                    isFormValid = false;
                    error['ShippingDetail_City'] = "Invalid! max:15"
                }
            }
            if (!ShippingDetail.State || !ShippingDetail.State === 'undefined') {
                isFormValid = false;
                error['ShippingDetail_State'] = "Required!"
            }
            if (ShippingDetail.State) {
                if (ShippingDetail.State.length > 15) {
                    isFormValid = false;
                    error['ShippingDetail_State'] = "Invalid! max:15"
                }
            }
            if (!ShippingDetail.Zip || !ShippingDetail.Zip === 'undefined') {
                isFormValid = false;
                error['ShippingDetail_Zip'] = "Required!*"
            }
            if (ShippingDetail.Zip) {
                if (ShippingDetail.Zip.length > 13) {
                    isFormValid = false;
                    error['ShippingDetail_Zip'] = "Invalid!"
                }
            }
        }
        setErrors(error)
        return isFormValid
    }


    const handleSubmit = e => {
        e.preventDefault();
        if (handleValidation()) {
            localStorage.setItem('UserDetails',JSON.stringify(UserDetails))
            setErrors({})
            setStep(Step + 1)
            navigate(`/check-out?step=${Step+1}`)
        }
    }

    return (
        <>
            <h3>Billing Address</h3>
            <form className="row g-3 p-2" onSubmit={handleSubmit}>

                <div className="col-12">
                    {/* <label htmlFor="inputAddress" className="form-label">Address*</label> */}
                    <input 
                    type="text" 
                    className="form-control" 
                    id="inputAddress" placeholder="Address*" value={BillingDetail.Address1} 
                    onChange={e => { setUserDetails({ ...UserDetails, BillingDetail: { ...UserDetails.BillingDetail, Address1: e.target.value } }) }} />
                    {("Address1" in Errors) ?
                        <span style={{ color: "red" }}>{Errors.Address1}</span>
                        : <></>
                    }
                </div>
                <div className="col-12">
                    {/* <label htmlFor="inputAddress2" className="form-label">Address 2 (optional)</label> */}
                    <input type="text" className="form-control" id="inputAddress2" placeholder="Address 2 (optional)" value={BillingDetail.Address2} onChange={e => { setUserDetails({ ...UserDetails, BillingDetail: { ...UserDetails.BillingDetail, Address2: e.target.value } }) }} />
                    {("Address2" in Errors) ?
                        <span style={{ color: "red" }}>{Errors.Address2}</span>
                        : <></>
                    }
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputCity" className="form-label">City</label>
                    <input type="text" className="form-control" id="inputCity" value={BillingDetail.City} onChange={e => { setUserDetails({ ...UserDetails, BillingDetail: { ...UserDetails.BillingDetail, City: e.target.value } }) }} placeholder="City*" />
                    {("City" in Errors) ?
                        <span style={{ color: "red" }}>{Errors.City}</span>
                        : <></>
                    }
                    {/* <Select 
                        isClearable={true}
                        isSearchable={true}
                        options={Cities.slice(0,10)}
                    />
    
                    
                    {("City" in Errors) ?
                        <span style={{ color: "red" }}>{Errors.City}</span>
                        : <></>
                    } */}
                </div>
                <div className="col-md-4">
                    {/* <label htmlFor="inputState" className="form-label">State</label> */}
                    <input type="text" className="form-control" id="inputCity" value={BillingDetail.State} onChange={e => { setUserDetails({ ...UserDetails, BillingDetail: { ...UserDetails.BillingDetail, State: e.target.value } }) }} placeholder="State*" />
                    {("State" in Errors) ?
                        <span style={{ color: "red" }}>{Errors.State}</span>
                        : <></>
                    }
                </div>
                <div className="col-md-2">
                    {/* <label htmlFor="inputZip" className="form-label">Zip</label> */}
                    <input type="text" className="form-control" id="inputZip" value={BillingDetail.Zip} onChange={e => { (!isNaN(e.target.value.replace('-', '')) && e.target.value.length < 13) && setUserDetails({ ...UserDetails, BillingDetail: { ...UserDetails.BillingDetail, Zip: e.target.value } }) }} placeholder="Zip Code*" />
                    {("Zip" in Errors) ?
                        <span style={{ color: "red" }}>{Errors.Zip}</span>
                        : <></>
                    }
                </div>
                <div className="col-4">
                    {/* <label htmlFor="inputAddress" className="form-label">Country</label> */}
                    <select type="text" className="df-check-out-country-title form-control" id="inputAddress" placeholder="US">
                        <option value="USA" selected>USA</option>
                    </select>

                </div>
                <div className='d-flex'>
                    <h4 className='p-2'>Ship to a different address?</h4>
                    <div className="p-2">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={isShiptoDiffAdd&&'on'} onChange={e => { setUserDetails({ ...UserDetails, isShiptoDiffAdd: e.target.checked ? true : false }) }} />
                    </div>
                </div>
                {isShiptoDiffAdd &&
                    <form className="row g-3 p-2 mt-0">
                        <h3>Shipping Address</h3>
                        <div className="col-md-6">
                            {/* <label htmlFor="firstName" className="form-label">First Name*</label> */}
                            <input type="text" className="form-control" id="firstName" value={ShippingDetail.first_name} onChange={e => { setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, first_name: e.target.value } }) }} placeholder="First Name*" />
                            {("ShippingDetail_first_name" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_first_name}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-md-6">
                            {/* <label htmlFor="lastName" className="form-label">Last Name*</label> */}
                            <input type="text" className="form-control" id="lastName" value={ShippingDetail.last_name} onChange={e => { setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, last_name: e.target.value } }) }} placeholder="Last Name*" />
                            {("ShippingDetail_last_name" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_last_name}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-12">
                            {/* <label htmlFor="Email" className="form-label">Email*</label> */}
                            <input type="email" className="form-control" id="Email" value={ShippingDetail.email} onChange={e => { setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, email: e.target.value } }) }} placeholder="Email*" />
                            {("ShippingDetail_email" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_email}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-12">
                            {/* <label htmlFor="Phone" className="form-label">Phone (optional)</label> */}
                            <input type="number" className="form-control" id="Phone" value={ShippingDetail.phone} onChange={e => {(e.target.value.length<15&&!isNaN(e.target.value.replace('+','').replace(' ','')))&&setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, phone: e.target.value } }) }} placeholder="Phone(optional)" />
                            {("ShippingDetail_phone" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_phone}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-12">
                            {/* <label htmlFor="inputAddress" className="form-label">Address*</label> */}
                            <input type="text" className="form-control" id="inputAddress" value={ShippingDetail.Address1} onChange={e => { setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, Address1: e.target.value } }) }} placeholder="Address*" />
                            {("ShippingDetail_Address1" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_Address1}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-12">
                            {/* <label htmlFor="inputAddress2" className="form-label">Address 2 (optional)</label> */}
                            <input type="text" className="form-control" id="inputAddress2" value={ShippingDetail.Address2} onChange={e => { setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, Address2: e.target.value } }) }} placeholder="Address2 (optional)" />
                            {("ShippingDetail_Address2" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_Address2}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-md-6">
                            {/* <label htmlFor="inputCity" className="form-label">City</label> */}
                            <input type="text" className="form-control" id="inputCity" value={ShippingDetail.City} onChange={e => { setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, City: e.target.value } }) }} placeholder="City*" />
                            {("ShippingDetail_City" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_City}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-md-4">
                            {/* <label htmlFor="inputState" className="form-label">State</label> */}
                            <input type="text" className="form-control" id="inputCity" value={ShippingDetail.State} onChange={e => { setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, State: e.target.value } }) }} placeholder="State*" />
                            {("ShippingDetail_State" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_State}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-md-2">
                            {/* <label htmlFor="inputZip" className="form-label">Zip</label> */}
                            <input type="text" className="form-control" id="inputZip" value={ShippingDetail.Zip} onChange={e => { (!isNaN(e.target.value.replace('-', '')) && e.target.value.length < 13) && setUserDetails({ ...UserDetails, ShippingDetail: { ...UserDetails.ShippingDetail, Zip: e.target.value } }) }} placeholder="Zip Code*" />
                            {("ShippingDetail_Zip" in Errors) ?
                                <span style={{ color: "red" }}>{Errors.ShippingDetail_Zip}</span>
                                : <></>
                            }
                        </div>
                        <div className="col-4">
                            {/* <label htmlFor="inputAddress" className="form-label">Country</label> */}
                            <select type="text" className="df-check-out-country-title form-control" id="inputAddress">
                                <option value="USA" selected>USA</option>
                            </select>
                        </div>
                    </form>
                }
                <div className="col-12 d-flex justify-content-between">
                    <button type="button" className="df-check-out-personal-btn btn btn-primary" onClick={e => { setStep(Step - 1) }}>Back</button>
                    <button type="submit" className="df-check-out-personal-btn btn btn-primary">Next</button>
                </div>
            </form>

        </>
    )
}

export default ShippingDetail