import axiosConfig from '../axiosConfig'
import {useState} from 'react';
import {useNavigate,Link} from 'react-router-dom';


const Filter = (props) => {

    const {
        filters,
        Dummyfilter,
        setDummyfilter,
        setProduct,
        setLoading,
        setError,
        Pages,
        searchWord,
        setPages,
        setappliedFilter,
        appliedFilter,
        SearchProduct,
        setfilter,
        filtersidebar
    } = props 

    const { closeMenu } = props

    const [rowToDisplay, setrowToDisplay] = useState({
        brand: 5,
        seller: 5,
        size: 5,
        category : 5
    })
    const navigate = useNavigate();
    const [categorySearch, setcategorySearch] = useState('')
    const [filtered_object, setfiltered_object] = useState({})
    const [priceFormValidation, setpriceFormValidation] = useState(true)

    /****** funtions ******/

    const clearFilter = () => {
        setLoading(false)
        setappliedFilter({
            freeShipping: false,
            onsale: false,
            seller: [],
            size: [],
            brand: [],
            category : [],
            price: ''
        })
        setPages({ ...Pages, current_page: 1 })
        setcategorySearch('')
        setfilter(Dummyfilter)
        navigate('/')

    }

    const showMore = e => {
        var name = e.target.name;
        if (filters[name].length > rowToDisplay[name]) {
            setrowToDisplay({
                ...rowToDisplay,
                [name]: rowToDisplay[name] + 5
            })
        }
    }

    const showLess = e => {
        var name = e.target.name;
        // //console.log(filters.name.length)
        setrowToDisplay({
            ...rowToDisplay,
            [name]: 5
        })
    }

    const onChange = e => {
        const searchedFilter = e.target.value
        if (searchedFilter === "") {
            axiosConfig.post('product')
                .then(res => {
                    if (res.data.success) {
                        setfilter({
                            //   ...filters,
                            onsale: res.data.data.isOnsale,
                            freeShipping: res.data.data.freeShipping,
                            seller: res.data.data.filter[0].seller,
                            brand: res.data.data.filter[0].brand,
                            size: res.data.data.filter[0].size,
                            price: res.data.data.price,
                            category: res.data.data.category,
                        })
                        setDummyfilter({
                            onsale: res.data.data.isOnsale,
                            freeShipping: res.data.data.freeShipping,
                            seller: res.data.data.filter[0].seller,
                            brand: res.data.data.filter[0].brand,
                            size: res.data.data.filter[0].size,
                            price: res.data.data.price,
                            category: res.data.data.category
                        })
                        setError()
                    } else {
                        setError(res.data.message)
                    }
                })
                .catch(err=>{
                    setError(err)
                })
                .finally(()=>{
                    setLoading(false)
                })

            setcategorySearch('')
        } else {
            setcategorySearch(searchedFilter)

            const filtered_values = Object.values(Dummyfilter).slice(2).map(arr => arr?.filter(item => item.toLowerCase().includes(searchedFilter.toLowerCase())))
            const keys = Object.keys(Dummyfilter).slice(2)

            keys.forEach((key, i) => {
                filtered_object[key] = filtered_values[i]
            })
            setfilter(filtered_object)
        }
    }

    const handlePriceRange = e =>{
        e.preventDefault();
        if(e.target[0].value&&e.target[1].value)
        {
            setpriceFormValidation(true)
            var min = parseInt(e.target[0].value);
            var max = parseInt(e.target[1].value);
            if (min>max) {
                let temp;
                temp = min;
                min = max;
                max = temp;
            }
            const priceRange = min+'-'+max;
            setappliedFilter({
                ...appliedFilter,
                price: priceRange
            })
            setPages({ ...Pages, current_page: 1 })
            e.target[0].value = null;
            e.target[1].value = null;
        }
        else{
            setpriceFormValidation(false)
        }

    }

    var key = 0
    return (
        <>
            <div className="col-md-3 filter_column" ref={filtersidebar} id="showsidebar">
                {/* <!-- Controller --> */}
                {/* <form> */}
                <div className="fillter-side">
                    <div className="category-d">
                        <span>Filter</span>
                        {appliedFilter.seller?.length > 0 |appliedFilter.category?.length>0| appliedFilter.brand?.length > 0 | appliedFilter.size?.length > 0 | appliedFilter.price !== '' | appliedFilter.freeShipping | appliedFilter.onsale ?
                            <button className='df-filter-clear btn-sm btn-primary m-2' onClick={()=>{clearFilter()}}>Clear all</button>
                            : <></>
                        }
                        {/* <button className='df-filter-hide-menu btn-sm btn-primary m-2' id='close-filter-menu' onClick={()=>{closeMenu()}}>X</button> */}
                         <button className='df-filter-hide-menu-before btn-sm btn-primary m-2' id='close-filter-menu' onClick={()=>{closeMenu()}}>X</button>
                        {/* <form onSubmit={handleSubmit}> */}
                        <div className="category">
                            <input type="search" onChange={onChange} value={categorySearch} className="form-control rounded" placeholder="Search filter...." aria-label="Search" aria-describedby="search-addon" />
                            <input type='submit' hidden />
                        </div>
                        {/* </form> */}
                    </div>
                    {(filters.freeShipping || filters.onsale) &&
                        <div className="seller-fl">

                            <>
                                <span>Only Show</span>
                                <div className="seller-checkbox">
                                    {filters.freeShipping &&

                                        <label className="checkbox text-truncate" title="Free Shipping">
                                            <input
                                                className="seller-checkbox__input"
                                                onChange={e => {
                                                    e.target.checked ?
                                                        setappliedFilter({
                                                            ...appliedFilter,
                                                            freeShipping: true
                                                        })
                                                        : setappliedFilter({
                                                            ...appliedFilter,
                                                            freeShipping: false
                                                        })
                                                    setPages({ ...Pages, current_page: 1 })
                                                }}
                                                type="checkbox"
                                                name="os_fs"
                                                id={`frShipping-checkboxId`}
                                                checked={appliedFilter.freeShipping}
                                            />
                                            <div className="seller-checkbox__box"></div>
                                            Free Shipping
                                        </label>
                                    }
                                    {filters.onsale &&
                                        <label className="checkbox d-block text-truncate" title='On Sale'>
                                            <input
                                                className="seller-checkbox__input"
                                                onChange={e => {
                                                    e.target.checked ?
                                                        setappliedFilter({
                                                            ...appliedFilter,
                                                            onsale: true
                                                        })
                                                        : setappliedFilter({
                                                            ...appliedFilter,
                                                            onsale: false
                                                        })
                                                    setPages({ ...Pages, current_page: 1 })
                                                }}
                                                type="checkbox"
                                                name="os_os"
                                                id={`onsale-checkboxId`}
                                                checked={appliedFilter.onsale}
                                            />
                                            <div className="seller-checkbox__box"></div>
                                            On Sale
                                        </label>
                                    }
                                </div>
                            </>
                        </div>
                    }
                    {(filters.price && filters.price.length !== 0 && filters.price[0] !== "") &&
                        <>
                            <div className="pricing-fl">
                                <span>Price</span>
                                <div className="p-radios">
                                    {filters.price?.map(prices => {
                                        key++
                                        if (prices) {
                                            return (
                                                <label key={key} htmlFor={`price-RadioId${key}`} className="radio text-truncate" title={prices}>
                                                    <input
                                                        type="radio"
                                                        value={prices}
                                                        onChange={e => {
                                                            setappliedFilter({
                                                                ...appliedFilter,
                                                                price: e.target.value
                                                            })
                                                            setPages({ ...Pages, current_page: 1 })
                                                        }}
                                                        name="price"
                                                        id={`price-RadioId${key}`}
                                                        className="price-radio__input"
                                                        checked={appliedFilter.price === prices}
                                                    />
                                                    <div className="price-radio__radio">
                                                    </div>
                                                    {prices} USD
                                                </label>
                                            )
                                        }
                                    })
                                    }
                                </div>
                            </div>
                            <form onSubmit={handlePriceRange}>
                                <div className="container row">
                                    <div className="col-md-3 p-0 ms-2">
                                        <input type="number" className="form-control df-form-control" placeholder="Min" aria-label="Min" />
                                    </div>
                                    <div className="col-md-1">
                                        -
                                    </div>
                                    <div className="col-md-3 p-0 ms-2">
                                        <input type="number" className="form-control df-form-control" placeholder="Max" aria-label="Max" />
                                    </div>
                                    <div className="col-md-2 p-0 ms-2">
                                        <input type="submit" className="btn btn-outline-primary fd-filter-price-button" value="Go" aria-label="Apply" />
                                    </div>
                                </div>
                                {!priceFormValidation&&
                                <span className='p-1' style={{color:'red'}}>Required!</span>
                                }
                            </form>
                        </>
                    }
                    {(filters.category && filters.category?.length !== 0 && filters.category[0] !== "") &&
                        <div className="brand-fl">
                            <span>Category</span>
                            <div className="brand-checkbox">
                                {filters.category?.slice(0, rowToDisplay.category + 1).map(cate => {
                                    key++
                                    if (cate) {
                                        return (
                                            <label key={key++} htmlFor={`category-checkboxId${key}`} className="checkbox d-block text-truncate" title={cate}>
                                                <input className="brand-checkbox__input"
                                                    type="checkbox"
                                                    onChange={e => {
                                                        e.target.checked ?
                                                            setappliedFilter({
                                                                ...appliedFilter,
                                                                category: [...appliedFilter.category, e.target.value]
                                                            })
                                                            : setappliedFilter({
                                                                ...appliedFilter,
                                                                category: appliedFilter.category.filter(event => (event !== e.target.value))
                                                            })
                                                        setPages({ ...Pages, current_page: 1 })
                                                    }}
                                                    value={cate}
                                                    name="category"
                                                    id={`category-checkboxId${key}`}
                                                    checked={appliedFilter.category.includes(cate)}
                                                />
                                                <div className="brand-checkbox__box"></div>
                                                {cate.toLowerCase()}
                                            </label>
                                        )
                                    }
                                })}
                                {filters.category && filters.category?.length !== 0 && filters.category[0] !== "" && filters.category?.length > 5 &&

                                    <>
                                        {filters.category?.length <= rowToDisplay.category ?
                                            <Link to={''} onClick={showLess} name="category" className='fd-sm-brand'>Less</Link>
                                            : <Link to={''} onClick={showMore} name="category" className='fd-sm-brand'>+5 More</Link>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }
                    {(filters.size && filters.size.length !== 0 && filters.size[0] !== "") &&
                        <div className="sizing-fl">
                            <span>Size</span>

                            <div className="s-radios">
                                {filters.size?.slice(0, rowToDisplay.size + 1).map(sizes => {
                                    key++
                                    if (sizes) {
                                        return (
                                            <label key={key++} htmlFor={`size-RadioId${key}`} className="radio text-truncate" title={sizes}>
                                                <input
                                                    type="radio"
                                                    onChange={e => {
                                                        e.target.checked ?
                                                            setappliedFilter({
                                                                ...appliedFilter,
                                                                size: [...appliedFilter.size, e.target.value]
                                                            })
                                                            : setappliedFilter({
                                                                ...appliedFilter,
                                                                size: appliedFilter.size.filter(event => (event !== e.target.value))
                                                            })
                                                        setPages({ ...Pages, current_page: 1 })
                                                    }}
                                                    value={sizes}
                                                    name="size"
                                                    checked={appliedFilter.size.includes(sizes)}
                                                    id={`size-RadioId${key}`}
                                                    className="size-radio__input"
                                                />
                                                <div className="size-radio__radio"></div>
                                                {sizes}
                                            </label>
                                        )
                                    }
                                })
                                }
                                {filters.size && filters.size.length !== 0 && filters.size[0] !== "" && filters.size?.length > 5 &&
                                    <>
                                        {filters.size?.length <= rowToDisplay.size ?
                                            <Link to={''} onClick={showLess} name="size" className='fd-sm-brand'>Less</Link>
                                            : <Link to={''} onClick={showMore} name="size" className='fd-sm-brand'>+5 More</Link>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }
                    {(filters.brand && filters.brand?.length !== 0 && filters.brand[0] !== "") &&
                        <div className="brand-fl">
                            <span>BRAND</span>
                            <div className="brand-checkbox">
                                {filters.brand?.slice(0, rowToDisplay.brand + 1).map(brands => {
                                    key++
                                    if (brands) {
                                        return (
                                            <label key={key++} htmlFor={`brand-checkboxId${key}`} className="checkbox d-block text-truncate" title={brands}>
                                                <input className="brand-checkbox__input"
                                                    type="checkbox"
                                                    onChange={e => {
                                                        e.target.checked ?
                                                            setappliedFilter({
                                                                ...appliedFilter,
                                                                brand: [...appliedFilter.brand, e.target.value]
                                                            })
                                                            : setappliedFilter({
                                                                ...appliedFilter,
                                                                brand: appliedFilter.brand.filter(event => (event !== e.target.value))
                                                            })
                                                        setPages({ ...Pages, current_page: 1 })
                                                    }}
                                                    value={brands}
                                                    name="brand"
                                                    id={`brand-checkboxId${key}`}
                                                    checked={appliedFilter.brand.includes(brands)}
                                                />
                                                <div className="brand-checkbox__box"></div>
                                                {brands.toLowerCase()}
                                            </label>
                                        )
                                    }
                                })}
                                {filters.brand && filters.brand?.length !== 0 && filters.brand[0] !== "" && filters.brand?.length > 5 &&

                                    <>
                                        {filters.brand?.length <= rowToDisplay.brand ?
                                            <Link to={''} onClick={showLess} name="brand" className='fd-sm-brand'>Less</Link>
                                            : <Link to={''} onClick={showMore} name="brand" className='fd-sm-brand'>+5 More</Link>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }
                    {/* Seller is remove from FleekDealz */}
                    {/* {(filters.seller && filters.seller?.length !== 0) &&
                        <div className="seller-fl">
                            <span>SELLER</span>
                            <div className="seller-checkbox">
                                {filters.seller?.slice(0, rowToDisplay.seller + 1).map(sellers => {
                                    key++
                                    if (sellers) {

                                        return (
                                            <label key={key++} htmlFor={`seller-checkboxId${key}`} className="checkbox d-block text-truncate" title={sellers}>
                                                <input
                                                    className="seller-checkbox__input "
                                                    onChange={e => {
                                                        e.target.checked ?
                                                            setappliedFilter({
                                                                ...appliedFilter,
                                                                seller: [...appliedFilter.seller, e.target.value]
                                                            })
                                                            : setappliedFilter({
                                                                ...appliedFilter,
                                                                seller: appliedFilter.seller.filter(event => (event !== e.target.value))
                                                            })
                                                        setPages({ ...Pages, current_page: 1 })
                                                    }}
                                                    value={sellers}
                                                    type="checkbox"
                                                    name="seller"
                                                    id={`seller-checkboxId${key}`}
                                                    checked={appliedFilter.seller.includes(sellers)}
                                                />
                                                <div className="seller-checkbox__box"></div>
                                                {sellers}
                                            </label>
                                        )
                                    }
                                })}
                                {filters.seller && filters.seller?.length !== 0 && filters.seller[0] !== "" && filters.seller?.length > 5 &&
                                    <>
                                        {
                                            filters.seller?.length <= rowToDisplay.seller ?
                                                <Link to={''} onClick={showLess} name="seller" className='fd-sm-brand'>Less</Link>
                                                : <Link to={''} onClick={showMore} name="seller" className='fd-sm-brand'>+5 More</Link>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    } */}

                    {/* <div className='d-flex justify-content-center mt-5'>
                        <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=22&l=ur1&category=beauty&banner=07K382C5YNG1DBEEZ4R2&f=ifr&linkID=defa42ef18ef56bd98d95d6cbeafd112&t=eshopping0e8-20&tracking_id=eshopping0e8-20" width="250" height="250" scrolling="no" border="0" marginWidth="0" style={{ border: 'none' }} frameBorder="0" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
                    </div> */}
                    {/* <div className='d-flex justify-content-center mt-3'>
                        <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=22&l=ur1&category=health&banner=1Y6NTDFNCVTM5TEESYG2&f=ifr&linkID=8ca431894d9f9013d650fd2a95ef023d&t=eshopping0e8-20&tracking_id=eshopping0e8-20" width="250" height="250" scrolling="no" border="0" marginWidth="0" style={{ border: 'none' }} frameBorder="0" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
                    </div> */}
                    {/* <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=49&l=ur1&category=health&banner=1PKK0JWBEV7WA9DQT702&f=ifr&linkID=1c741154ec7d6db8b5aa8355d723d069&t=eshopping0e8-20&tracking_id=eshopping0e8-20" width="270" height="600" scrolling="no" border="0" marginWidth="0" style={{border:'none'}}frameBorder="0" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe> */}
                </div>
                {/* </div> */}
                {/* </form> */}
            </div>
        </>
    )
}

export default Filter
