import React, {useState}from 'react'
import { useNavigate } from 'react-router-dom'

const PersonalDetail = (props) => {

    const {first_name , last_name , email , phone , isCreateAccount} = props.UserDetails

    const {setUserDetails,UserDetails} = props
    const {setStep,Step ,setCookies} = props
    const [Errors, setErrors] = useState({})
    const navigate =  useNavigate()

    const handleValidation = () => {
        let isFormValid = true;
        var error = {}
        if (!first_name||first_name==='undefined') {
            isFormValid = false;
            error['first_name'] = "Required!"
        }
        if (!last_name||last_name==='undefined') {
            isFormValid = false;
            error['last_name'] = "Required!"
        }
        if (!email||email==='undefined') {
            isFormValid = false;
            error['email'] = "Required!"
        }
        if (email) {
            if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
                isFormValid = false;
                error['email'] = "invalid!"
            }
        }
        if (phone) {
            // if (!(/^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/.test(phone))) {
            //     isFormValid = false;
            //     error['phone'] = "invalid!"
            // }
            if(phone.replace(' ','').replace('-','').replace('+','').replace('(','').replace(')','').length<10)
            {
                isFormValid = false;
                error['phone'] = "invalid! min 10 digits"
            }
            if(phone.replace(' ','').replace('-','').replace('+','').replace('(','').replace(')','').length>13)
            {
                isFormValid = false;
                error['phone'] = "invalid! max 13 digits"
            }
        }
        setErrors(error)
        return isFormValid
    }

    const handleSubmit = e =>{
        e.preventDefault();
        if (handleValidation()) {
            localStorage.setItem('UserDetails',JSON.stringify(UserDetails))
            setErrors([])
            setStep(Step+1)
            navigate(`/check-out?step=${Step+1}`)
        }
    }
    
    return (
        <form className="row g-3 p-2" onSubmit={handleSubmit}>
            <div className="col-md-6">
                {/* <label htmlFor="firstName" className="form-label">First Name*</label> */}
                <input type="text" className="form-control" id="firstName" value={first_name} onChange={e=>{setUserDetails({...UserDetails,first_name:e.target.value})}}  placeholder="First Name*"/>
                {("first_name" in Errors)?
                <span style={{ color: "red" }}>{Errors.first_name}</span>
                :<></>
                }
            </div>
            <div className="col-md-6">
                {/* <label htmlFor="lastName" className="form-label">Last Name*</label> */}
                <input type="text" className="form-control" id="lastName" value={last_name} onChange={e=>{setUserDetails({...UserDetails,last_name:e.target.value})}}  placeholder="Last Name*"/>
                {("last_name" in Errors)?
                <span style={{ color: "red" }}>{Errors.last_name}</span>
                :<></>
                }
            </div>
            <div className="col-12">
                {/* <label htmlFor="Email" className="form-label">Email*</label> */}
                <input type="email" className="form-control" id="Email" value={email} onChange={e=>{setUserDetails({...UserDetails,email:e.target.value})}} placeholder="Email*" />
                {("email" in Errors)?
                <span style={{ color: "red" }}>{Errors.email}</span>
                :<></>
                }
            </div>
            <div className="col-12">
                {/* <label htmlFor="Phone" className="form-label">Phone (optional)</label> */}
                <input type="text" className="form-control" id="Phone" value={phone} onChange={e=>{(e.target.value.length<15&&!isNaN(e.target.value.replace('+','').replace(' ','')))&&setUserDetails({...UserDetails,phone:e.target.value})}} placeholder="Phone(optional)" />
                {("phone" in Errors)?
                <span style={{ color: "red" }}>{Errors.phone}</span>
                :<></>
                }
            </div>
            {/* <div className="col-12">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck" onChange={e=>{setUserDetails({...UserDetails,isCreateAccount:e.target.checked?true:false})}} />
                    <label className="form-check-label" htmlFor="gridCheck">
                        Create my Account
                    </label>
                </div>
            </div> */}
            <div className="col-12 d-flex justify-content-end">
                <button type="submit" className="df-check-out-personal-btn btn btn-primary">Next</button>
            </div>
        </form>
    )
}

export default PersonalDetail