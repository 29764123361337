import React from 'react'
import { useState } from 'react'
import axiosConfig from '../../axiosConfig'


const Profile = ({setAuthToken}) => {

	const user = JSON.parse(localStorage.getItem('AuthUser'))
	const [UserDetail, setUserDetail] = useState({})
	const [Errors, setErrors] = useState({})
	const [Loading, isLoading] = useState(false)
	

	const showPassword = (e,id) => {
		if (document.getElementById(id).type == "password") {
			document.getElementById(id).setAttribute('type', 'text')
			e.target.className = 'fas fa-eye-slash'
		} else {
			document.getElementById(id).setAttribute('type', 'password')
			e.target.className = 'fas fa-eye'
		}
	}

	const handleChange = e =>{
		const name = e.target.name;
		const value = e.target.value;
		setUserDetail({...UserDetail,[name]:value})
	}
	const changePassword = e =>{
		isLoading(true)
		e.preventDefault()
		setErrors({})

		UserDetail['email'] = user?.email

		axiosConfig.post('/change-password',UserDetail)
			.then(res=>{
				if(res.data.status==400){
					setErrors(res.data.messages)
					console.log(res.data.messages)
				}
				if(res.data.status==200){
					setAuthToken(res.data.token)
				}
				console.log(res.data)
			}).catch(err=>{
				console.log(err?.response)
			}).finally(()=>{
				isLoading(false)
			})
		
	}

	return (
		
		<div class="container pt-5">
			<div class="row pt-5">
				<h1 class="fw-bold pt-5" >User Profile</h1>
				<div class="col-md-6 pt-3">
					{/* <!-- User name --> */}
					<div class="form-outline mb-4 ss-us-pro-ban" id="ss-us-edit-ban">
						<label class="form-label" for=""><i class="fas fa-user-edit"></i> User Name</label>
						<input type="text" id="UserName" class="form-control" value={user?.name} name="name" readOnly />
					</div>

					{/* <!-- User name --> */}
					<div class="form-outline mb-4 ss-us-pro-ban" id="ss-us-edit-ban">
						<label class="form-label" for=""><i class="fas fa-envelope-open-text"></i>  Email Address</label>
						<input type="text" id="UserEmail" class="form-control" value={user?.email} name="name" readOnly />
					</div>
					<form onSubmit={changePassword}>
						<div class="form-outline mb-4 ss-us-pro-ban" id="ss-us-edit-ban">
							<label class="form-label" for=""> Old Password</label>
							<input 
								type="password" 
								id="OldPassword" 
								class="form-control" 
								placeholder="Enter Old Password..." 
								name="OldPassword"
								onChange={handleChange} />
							<button
								type="button"
								class="p-0 m-0 lh-1 fd-lo-fo-ic btn"
							>
								<i
									class="fas fa-eye"
									onClick={(e)=>showPassword(e,"OldPassword")}
								>
								</i>
							</button>
							{("OldPassword" in Errors) ?
								<span style={{ color: "red" }}>{Errors.OldPassword}</span>
								: <></>
							}
						</div>
						<div class="form-outline mb-4 ss-us-pro-ban" id="ss-us-edit-ban">
							<label class="form-label" for=""> New Password</label>
							<input 
								type="password" 
								id="NewPassword" 
								class="form-control" 
								placeholder="Enter New Password..." 
								name="NewPassword"
								onChange={handleChange}
								/>
							<button
								type="button"
								class="p-0 m-0 lh-1 fd-lo-fo-ic btn"
							>
								<i
									class="fas fa-eye"
									onClick={(e)=>showPassword(e,"NewPassword")}
								>
								</i>
							</button>
							{("NewPassword" in Errors) ?
								<span style={{ color: "red" }}>{Errors.NewPassword}</span>
								: <></>
							}
						</div>

						<div class="form-outline mb-4 ss-us-pro-ban" id="ss-us-edit-ban">
							<label class="form-label" for=""> Confirm New Password</label>
							<input 
								type="password" 
								id="ConfirmPassword" 
								class="form-control" 
								placeholder="Re-Enter New Password..." 
								name="ConfirmPassword"
								onChange={handleChange}
								/>
							<button
								type="button"
								class="p-0 m-0 lh-1 fd-lo-fo-ic btn"
							>
								<i
									class="fas fa-eye"
									onClick={(e)=>showPassword(e,"ConfirmPassword")}
								>
								</i>
							</button>
							{("ConfirmPassword" in Errors) ?
								<span style={{ color: "red" }}>{Errors.NewPassword}</span>
								: <></>
							}
						</div>

						<button type="submit" class="ss-ck-ti-ri-ban">
							{Loading?"Loading...":"UPDATE"}
						</button>
					</form>
				</div>
				<div class="col-md-6">
					<div class="fd-us-pr-ban">
						<h4>Billing Address <i class="far fa-address-card"></i></h4>
						<h3>{user?.first_name + " " + user?.last_name}</h3>
						<p>{user?.address_1}</p>
						{user?.address_2 &&
							<p>{user?.address_2}</p>
						}
						<p>{user?.city + " " + user?.postcode}</p>
						<p>{user?.state}</p>
						<p>{user?.country}</p>
						<hr />
						<div class="fd-us-pr-em">
							<p>Billing Email : <span><a>{user?.email}</a></span></p><br />
							<p>Billing Phone No : <span>{user?.phone}</span></p>
						</div>
					</div>
					{/* <div class="fd-us-pr-ban">
						<h4>DELIVERY ADDRESS <i class="far fa-address-card"></i></h4>
						<h3>M Irfan</h3>
						<p>C-632</p>
						<p>Siddhivinayak Tower Near Kataria Motors</p>
						<p>Maqaraba Sarkhej 15003</p>
						<p>Ahmedabad</p>
						<p>INDIA</p>
						<div class="fd-us-pr-em">
							<p>Billing Phone No : <span>+91 85308 22023 </span></p>
							<p>Billing Email : <span><a > xyz@gmail.com </a></span></p>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	)
}

export default Profile