import React from 'react'
import { useCookies } from 'react-cookie'
import { Navigate } from 'react-router-dom'

const Logout = ({isAuthenticate,setAuthToken,setisAuthenticate}) => {

    const [cookies,setCookie,removeCookie] = useCookies()

    if(isAuthenticate){
        setAuthToken(null)
        setisAuthenticate(false)
        removeCookie('fduat',{
            path: `${process.env.REACT_APP_PUBLIC_URL}`
        })
        
    }
    return <Navigate to={`/`} />
  
}

export default Logout