import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from 'react-router-dom';

function GetProduct() {
  const {link} = useParams();
  window.location.replace(atob(link))
  return null
}

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(document.getElementById('root'));
// const {pathname} = useLocation();

if (rootElement.hasChildNodes()) {
  root.hydrate(
    <Router>
    <Routes>
      <Route path={`${process.env.REACT_APP_PUBLIC_URL}*`} element={<App key={window.location.pathname} title="Fleekdealz"/>}/>
      <Route exact path= {`${process.env.REACT_APP_PUBLIC_URL}search/:keyword/*`} element={<App key={window.location.pathname} title="Fleekdealz"/>}/>
      <Route exact path={`${process.env.REACT_APP_PUBLIC_URL}clk/:link`} element={<GetProduct/>}/>
      <Route path="*" element={<Navigate to ={`${process.env.REACT_APP_PUBLIC_URL}`} />}/>
    </Routes>
  </Router>,
  rootElement);
} else {
  root.render(
    <Router>
    <Routes>
      <Route path={`${process.env.REACT_APP_PUBLIC_URL}*`} element={<App key={window.location.pathname} title="Fleekdealz"/>}/>
      <Route exact path= {`${process.env.REACT_APP_PUBLIC_URL}search/:keyword/*`} element={<App key={window.location.pathname} title="Fleekdealz"/>}/>
      <Route exact path={`${process.env.REACT_APP_PUBLIC_URL}clk/:link`} element={<GetProduct/>}/>
      <Route path="*" element={<Navigate to ={`${process.env.REACT_APP_PUBLIC_URL}`} />}/>
    </Routes>
  </Router>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
