//axios congigration
import axiosConfig from '../axiosConfig';

// library and packages
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import ProductCard from './ProductCard';
import { LazyLoadImage } from 'react-lazy-load-image-component'


const Item = (props) => {

    const { Cart, setCart, setCartProduct, CartProduct } = props

    const {sku, slug } = useParams();
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [Product, setProduct] = useState(null)
    const [Error, setError] = useState('')
    const [SuggestedProduct, setSuggestedProduct] = useState([])
    const [AddtinoalDesc, setAddtinoalDesc] = useState()
    const [displayImage, setDisplayImage] = useState()
    const [Reviews, setReviews] = useState([])
    const [AvgRating, setAvgRating] = useState(0)
    const [Limit, setLimit] = useState(5)
    const [TotalReviews, setTotalReviews] = useState(0)
    
    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const random = randomIntFromInterval(440,678)

    useEffect(() => {
        const getProduct = async () => {
            await axiosConfig.post(`product/${sku}/${slug}`)
                .then(res => {
                    setProduct(res.data.data.product[0])
                    setSuggestedProduct(res.data.data.suggested_product)
                })
                .catch(err => { setError('This porduct is not found !') })
                .finally()
        }
        getProduct()


        return (() => {
            setProduct(null)
            setAddtinoalDesc()
            setError('')
        })
    }, [pathname])

    const getReviews = async () => {
        await axiosConfig.post('getReviews/',{
            category : Product.items.category.hierarchy,
            product : sku,
            limit : Limit
        }).then(res=>{
            if(res.status==200){
                setReviews(res.data.data)
                setAvgRating(res.data.average)
                setTotalReviews(res.data.total+random)
            }  
        })
    }

    const showMore = () => {
        setLimit(Limit+5)
    }
    
    useEffect(() => {
      if (Product) {
        getReviews()
      }
    }, [Limit])
    


    useEffect(() => {
       
        if (Product) {
            
            if (Array.isArray(Product.items.image_link)) {
                Product.items.image_link.map((link, key) => {
                    const url = process.env.REACT_APP_API_URL + '/products/images/' + Product.title + '/' + link.split('/').at(-2) + "_" + link.split('/').at(-1)
                    Product.items.image_link[key] = url
                })
                setDisplayImage(Product?.items.image_link[0])
            }
            else {
                Product.items.image_link = process.env.REACT_APP_API_URL + '/products/images/' + Product.title + '/' + Product.items.image_link.split('/').at(-2) + "_" + Product.items.image_link.split('/').slice(-1)
            }
            if (Product?.items?.additional_info) {
                const Desc = Object?.entries(Product?.items?.additional_info)
                    ?.map((ele) => {
                        // console.log(ele)
                        if (ele) {
                            return (<li className='fd-aditional-info-banner'> <p className='m-0 p-0 pt-1'><strong className='text-secondary me-2'>{ele[0] + ':'}</strong>{ele[1]}</p></li>)
                        }
                        else { return null }
                    })
                // console.log(Desc);
                setAddtinoalDesc(Desc)
            }
            getReviews()
        }

        return () => {
            setAddtinoalDesc()
            setAvgRating(0)
            setReviews([])
            setLimit(5)
            setTotalReviews(0)
        }
    }, [Product])


    function countProductClick(e, fid, pid) {
        e.preventDefault()
        axiosConfig.post('product/Clk', {
            fid: fid,
            pid: pid.$oid
        })
        navigate(`/product/${fid}/p-${pid?.$oid}`)
        window.scrollTo(0, 0)
    }

    // console.log(Error)

    // console.log(AddtinoalDesc)

    const AddToCart = async (ProductId) => {
        // console.log(ProductId)
        const product = {
            id: ProductId.$oid,
            qty: 1
        }
        setCart([...Cart, product])
        // await cookies.set('cart',{
        //     path: '/',
        //     expires : new Date(Date.now()+86400000),
        // })
        // console.log(cookies.get('cart'))
    }

    const RemoveFromCart = async (ProductId) => {
        // setCart(Cart?.filter(item=>{return item !== Product &&item}))
        setCart(Cart?.filter(item => { return item.id !== ProductId.$oid && item }))
        setCartProduct(CartProduct.filter(item => { return item.items._id.$oid !== ProductId.$oid && item }))
        // await cookies.set('cart','hello word',{
        //     path: '/',
        //     expires : new Date(Date.now()+86400000),
        // })
    }

    const quantity = Cart?.filter(e => e.id === Product?.items?._id?.$oid && e)[0]?.qty

    return (
        <>
            {Product ?
                <>

                    <Helmet>
                        <title>{Product.items.title}</title>
                        <meta name="description" content={Product.items.description} />
                        <meta name="theme-color" content="#E6E6FA" />
                        <meta itemprop="name" content={Product.items.title} />
                        <link itemprop="image" to={Product.items.image_link} />
                        <script type="application/ld+json">
                            {`
                                {
                                    "@context": "https://schema.org/",
                                    "@type": "Product",
                                    "name": "${Product.items.title}",
                                    "image": ["${Product.items.image_link}"],
                                    "description": "${Product.items.description}",
                                    "brand": {
                                        "@type": "Brand",
                                        "name": "${Product.items.brand}"
                                    },
                                    "offers": {
                                        "@type": "Offer",
                                        "priceCurrency": "USD",
                                        "price": "${Product.items.price}",
                                        "availability": "InStock"
                                      }
                                }
                            `}
                        </script>

                    </Helmet>
                    <div className="fleekDeals-single-banner">
                        {/* <span>{}</span> */}
                        
                        <div className="container">
                        <nav aria-label="breadcrumb" >
                        <ol className="breadcrumb">
                            {Product.items.category?.hierarchy?.split(">>").map((cate,idx)=>{
                                // cate = 
                                if((Product.items.category.hierarchy.split(">>").length-1)==idx){
                                    return (
                                    
                                        <li className="breadcrumb-item fd-us-bdc-pr-ban active"><Link to={`/search/${cate.replaceAll(' ',"+")}/`} className="text-decoration-none text-secondary">{cate}</Link></li>
                                    )
                                }
                                return (
                                    
                                    <li className="breadcrumb-item fd-us-bdc-pr-ban"><Link to={`/search/${cate.replaceAll(' ',"+")}/`}>{cate}</Link></li>
                                )
                            })

                            }
                        </ol>
                        </nav>
                            <div className="row">

                                {Array.isArray(Product.items.image_link) ?

                                    <div className="col-md-6 ">
                                        {Product.items.sale_price ?
                                            <p className="fd-single-save-icon">Save {100 - Math.ceil((Product.items.sale_price * 100) / Product.items.price)}%</p> : <></>
                                        }
                                        <div className='fd-single-side-banner fd-single-product-img'>

                                            {/* <div className=''> */}
                                            <LazyLoadImage
                                                    src={displayImage}
                                                    alt=""
                                                    className="img-fluid fd-single-img"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = '/image/image-not-available.png'
                                                    }}
                                                />
                                                
                                            {/* </div> */}
                                        </div>
                                        <div className='d-flex fd-singal-card' >
                                            {Product.items.image_link?.map((img, i) => {
                                                var tempImgArray = img.split('/')
                                                tempImgArray.pop()
                                                tempImgArray.push('thumbnail_' + img.split('/').at(-1))
                                                const image = tempImgArray.join('/')
                                                //console.log()
                                                return (
                                                    <div key={i} className={`border border-1 m-2 ${img === displayImage && 'df-single-mini-img'}`}>
                                                         <LazyLoadImage
                                                            src={image}
                                                            alt=""
                                                            className={`img-fluid`}
                                                            width={80} height={80}
                                                            onClick={(e) => { setDisplayImage(img) }}
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = '/image/image-not-available.png'
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    <div className="col-md-6 fd-single-side-banner">
                                        {Product.items.sale_price ?
                                            <p className="fd-single-save-icon">Save {100 - Math.ceil((Product.items.sale_price * 100) / Product.items.price)}%</p> : <></>
                                        }
                                        <div className='fd-single-product-img'>
                                            <img src={Product.items.image_link} alt="" className="img-fluid fd-single-img" />
                                        </div>
                                    </div>
                                }
                                <div className="col-md-6">
                                    <div className="fd-single-dec-main">
                                        {/* {Product.items.google_product_category */}
                                        <div className="fd-single-main-text">
                                            <h1 className="fd-single-title">{Product.items.title}</h1>
                                            {(AvgRating>0&&TotalReviews>0)&&
                                            <>
                                                <div className="d-flex align-items-center pb-2">
                                                    <div className="ratings">
                                                        {[...Array(5)].map((i,idx)=>{
                                                            if (idx<=AvgRating-1) {

                                                                return <i className="fa fa-star rating-color"></i>
                                                            }
                                                            return <i className="fa fa-star" ></i>
                                                            
                                                        })
                                                        }
                                                        
                                                    </div>
                                                    <h5 className="review-stat text-dark">{AvgRating} out of 5</h5> 
                                                </div>
                                                <h5 className="review-stat">{TotalReviews} Review(s)</h5>
                                            </>    
                                            }
                                            {/* <h2 className="fd-single-seller"><Link to={`https://${Product.link}`} target='_blank' title={Product.link}>{Product.seller}</Link></h2> */}
                                            {Product.items.sale_price ?
                                                <p className="fd-single-main-price"><span className="fleekDeals-single-price">${Product.items.sale_price < 10 ? "0" + parseFloat(Product.items.sale_price) : parseFloat(Product.items.sale_price)}</span><span className="fleekDeals-single-less-price">${Product.items.price < 10 ? '0' + parseFloat(Product.items.price).toFixed(2) : parseFloat(Product.items.price).toFixed(2)}</span></p>
                                                : <p className="fd-single-main-price"><span className="fleekDeals-actual-price">${Product.items.price < 10 ? '0' + parseFloat(Product.items.price).toFixed(2) : parseFloat(Product.items.price).toFixed(2)}</span></p>
                                            }
                                            {Product.items.shipping === 0 ?
                                                <p className="fd-single-shipping mb-0">Free Delivery</p>
                                                : <p className="fd-single-plus-shipping mb-0">
                                                    +${parseFloat(Product.items.shipping).toFixed(2)} Delivery ,{Product.items.Shipping_Method}
                                                </p>
                                            }
                                            <p className='fd-single-plus-shipping'>Delivery within 5-8 Days</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="fd-single-detial-main">
                                                    {Product.items.brand &&
                                                        <p className="fd-single-main-detail m-0"><span className="">Brand :</span><span className="fd-single-main-detail-text">{Product.items.brand}</span></p>
                                                    }
                                                    {Product.items.Packaging_Size &&
                                                        <p className="fd-single-main-detail m-0"><span className="">Packaging Size :</span><span className="fd-single-main-detail-text">{Product.items.Packaging_Size}</span></p>
                                                    }
                                                    {Product.items.Packaging_Weight &&
                                                        <p className="fd-single-main-detail m-0"><span className="">Packaging Weight :</span><span className="fd-single-main-detail-text">{Product.items.Packaging_Weight}</span></p>
                                                    }
                                                    {Product.items.availability &&
                                                        <p className="fd-single-main-detail m-0"><span className="">Availability :</span><span className="fd-single-main-detail-text">{Product.items.availability}</span></p>
                                                    }
                                                    {Product.items.gtin &&
                                                        <p className="fd-single-main-detail m-0"><span className="">gtin :</span><span className="fd-single-main-detail-text">{Product.items.gtin}</span></p>
                                                    }
                                                    {Product.items.mpn &&
                                                        <p className="fd-single-main-detail m-0"><span className="">mpn :</span><span className="fd-single-main-detail-text">{Product.items.mpn}</span></p>
                                                    }
                                                    {Product.items.upc &&
                                                        <p className="fd-single-main-detail m-0"><span className="">upc :</span><span className="fd-single-main-detail-text">{Product.items.upc}</span></p>
                                                    }
                                                    {Product.items.ean &&
                                                        <p className="fd-single-main-detail m-0"><span className="">upc :</span><span className="fd-single-main-detail-text">{Product.items.ean}</span></p>
                                                    }
                                                    {Product.items.SKU &&
                                                        <p className="fd-single-main-detail m-0"><span className="">sku :</span><span className="fd-single-main-detail-text">{Product.items.SKU}</span></p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="fd-single-detial-main">
                                                    {Product.items.color &&
                                                        <>
                                                            {/* {console.log(Product.items.color)} */}
                                                            <p className="fd-single-main-detail m-0"><span className="">Color(s) :</span></p>
                                                            {Array.isArray(Product.items.color) ?
                                                                <>
                                                                    {Product.items.color?.slice(0, 12).map(item => {
                                                                        return <div className="form-check fd-single-color-radio" >{item}</div>
                                                                    })
                                                                    }
                                                                </>
                                                                : <div className="form-check fd-single-color-radio" >{Product.items?.color}</div>
                                                            }
                                                        </>
                                                    }
                                                    {Product.items.size &&
                                                        <p className="fd-single-main-detail m-0"><span className="">Size :</span>{Product.items.size}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md'>
                                                    {Product.items.qty > 0 ?
                                                        !Cart?.some(e => e.id === Product.items._id.$oid) ?
                                                            <p className="fd-single-description"><button onClick={() => AddToCart(Product.items._id)} type="button" className="fd-single-add-cart-btn"><i className="fa fa-shopping-cart me-2" aria-hidden="true"></i>Add to Cart</button></p>
                                                            :
                                                            <div className='fd-btn-p-g '>
                                                                <div className='fd-single-delete-btn'>
                                                                    <button onClick={() => RemoveFromCart(Product.items._id)} type="button" className="btn fd-p-btn-trash">
                                                                        <i className="fa fa-trash me-2" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                                <div className='fd-item-btn-group text-center'>
                                                                    <button id='decrBtn' className=' btn text-center text-white fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === Product.items._id.$oid) { item.qty-- } return item })) }} disabled={quantity === 1}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                                    <button className='btn text-white' type='button'>{quantity}</button>
                                                                    <button id='incrBtn' className='btn text-center text-white fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === Product.items._id.$oid) { item.qty++ } return item })) }}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>

                                                        // <div className='fd-btn-p-g row '>
                                                        //     <div className='col-md-4 d-col-none'>
                                                        //     <button onClick={() => RemoveFromCart(Product.items._id)} type="button" className="btn fd-p-btn-trash">
                                                        //            <i className="fa fa-trash me-2" aria-hidden="true"></i>
                                                        //     </button>
                                                        //     </div>
                                                        //     <div className='col-md-8 row fd-item-btn-group text-center'>
                                                        //             <button id='decrBtn' className='col-md-4 btn text-center text-white fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === Product.items._id.$oid) { item.qty-- } return item })) }} disabled={quantity === 1}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                        //             <button className='col-md-4 btn text-white' type='button'>{quantity}</button>
                                                        //             <button id='incrBtn' className='col-md-4 btn text-center text-white fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === Product.items._id.$oid) { item.qty++ } return item })) }}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                        //     </div>
                                                        // </div>






                                                        // <p className="fd-single-description">
                                                        //     <div className='fd-single-add-cart-btn w-50 d-flex justify-content-around'>
                                                        //         <button onClick={() => RemoveFromCart(Product.items._id)} type="button" className="btn">
                                                        //             <i className="fa fa-trash me-2" aria-hidden="true"></i>
                                                        //         </button>
                                                        // <div className='btn-group row fd-cart-btn-group'>
                                                        //     <button id='decrBtn' className='col-md-4 btn text-center text-secondary fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === Product.items._id.$oid) { item.qty-- } return item })) }} disabled={quantity === 1}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                        //     <p className='col-md-4 text-center fw-normal p-1 m-0'>{quantity}</p>
                                                        //     <button id='incrBtn' className='col-md-4 btn text-center text-secondary fw-normal p-1' type='button' onClick={() => { setCart(Cart.map(item => { if (item.id === Product.items._id.$oid) { item.qty++ } return item })) }}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                        // </div>
                                                        //     </div>
                                                        // </p>
                                                        : <p className="fd-single-description"><button type="button" className="fd-single-add-cart-btn bg-danger" disabled>OUT OF STOCK</button></p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {Product.items.description && Product.items.description.length > 15 &&
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="fd-single-des-title pt-5 ">
                                            <h5 className="">Description : </h5>
                                            <p className="">{<span dangerouslySetInnerHTML={{ __html: Product.items.description.split(';').join(' ') }} />}</p>
                                            {AddtinoalDesc ?
                                                <div className='border-top border-secondary'>
                                                    <h5 className='text-secondary pt-3' >Additional Information</h5>
                                                    <ul className='fd-additional-info-des ps-4'>
                                                        {AddtinoalDesc}
                                                    </ul>
                                                </div>
                                                : <></>}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(AvgRating>0&&TotalReviews>0)&& 
                            <>
                                <div className="fd-single-suggected-title pt-3">
                                    <div className='card m-1'>
                                        <div class="card-body bg-primary">
                                            <div className=''>
                                                <h3 className="col-6">Reviews</h3>
                                            </div>
                                            <div className="d-flex align-items-center pb-2">
                                                <div className="ratings">
                                                {[...Array(5)].map((i,idx)=>{
                                                    if (idx<=AvgRating-1) {

                                                        return <i className="fa fa-star rating-color"></i>
                                                    }
                                                    return <i className="fa fa-star" ></i>
                                                    
                                                })
                                                }
                                                </div>
                                                <h5 className="review-stat text-dark">{AvgRating} out of 5</h5> 
                                            </div>
                                            <h3 className="review-stat text-dark fw-bolder">{TotalReviews} Reviews</h3>
                                            
                                        </div>
                                    </div>
                                    
                                    {Reviews.map(review=>{
                                        return(
                                            <div class="card m-1">
                                                <div class="card-body">
                                                    <div className='d-flex'>
                                                    <h5 class="card-title">
                                                        <i className="fa fa-user-circle text-primary" aria-hidden="true"></i>
                                                        <span className='ms-2'>{review.username}</span>
                                                    </h5>
                                                    {review.isVarified&&<small className='ms-2 text-warning p-1'>verified</small>}
                                                    </div>
                                                    <div className="d-flex align-items-center pb-2">
                                                        <div className="ratings">
                                                        {[...Array(5)].map((i,idx)=>{
                                                                if (idx<=review.rating-1) {

                                                                    return <i className="fa fa-star rating-color"></i>
                                                                }
                                                                return <i className="fa fa-star" ></i>
                                                                
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                    <p class="card-text">{review.comment}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {TotalReviews>Reviews.length&&
                                    <div className='d-flex justify-content-center'>    
                                        <button
                                            className='btn-sm btn-secondary df-bc-pr-btn m-1' 
                                            onClick={()=>showMore()}
                                        >More...</button>
                                    </div>
                                    }
                                </div>
                                <hr />
                            </>
                            }
                            <div className="fd-single-suggected-title pt-3">
                                <h5 className="">Related Products : </h5>
                                <div className="row pdct_row">
                                    <ProductCard
                                        Item={true}
                                        className={"col-md-3 col-6 pdct_column fd-related-cart-banner"}
                                        Product={SuggestedProduct}
                                        Error={Error}
                                        Cart={Cart}
                                        CartProduct={CartProduct}
                                        setCart={setCart}
                                        setCartProduct={setCartProduct}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                (Error ?
                    <div className='fleekDeals-single-banner'>
                        <div className='container m-s'>
                            <p className='fs-2'>{Error}</p>
                        </div>
                    </div>
                    :
                    <div className='fleekDeals-single-banner'>
                    <div className="fd-ld-all-wrapper">
                    <div className="fd-ld-all-dots">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    </div>
                </div>
                   
                )
            }
        </>
    )
}

export default Item