

import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const ContactUs = () => {

    return (
        <>
            <Helmet>
                <title>FleekDealz</title>
            </Helmet>
            <div className="container">
                   <div className='fd-ct-min-cn-ban'>
                {/* </section> */}
                <div className='row mt-5'>
                    {/* <div className='col-md-6 d-flex justify-content-center'>
                        <div className='fd-ct-cn-ban '>
                            <h1 className="fd-re-ma-ti">Contact Us</h1>
                            <form>
                                 <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Your Name</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="name" required />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Subject</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="text" required />
                                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Your message</label>
                                    <textarea type="text" id="message" name="message" rows="2" class="form-control md-textarea" required></textarea>
                                </div>
                                <button type="submit" class="fd-ct-cn-btn btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div> */}
                    <div className='col-md-6'>
                        <div className='fd-ct-si-cn-ban'>
                            {/* <h1 className="fd-retail-main-title ">Get in touch</h1> */}
                            <h1 className="fd-retail-main-title ">CONTACT US</h1>
                            {/* <p className='fd-ct-si-cn-phera'>FleekDealz Platform is the place where online shopping marketplaces come in mind. It isn’t an online marketplace but it is more of Free Product Ads platform, it gives consumers/buyers the same opportunity to browse a variety of different products based on their search criteria.</p> */}
                            <h5 className="mt-5"><strong>Address :</strong></h5>
                            <p className='fd-ct-si-cn-ad'><span>4513 Wimbleton court,</span><br/> <span>Tallahassee FL 32303.</span></p>
                            <p className='fd-ct-si-cn-ad'><span>Email : <Link to={`${process.env.REACT_APP_PUBLIC_URL}`}>sales@fleekdealz.com</Link></span></p>
                            <p className='fd-ct-si-cn-ad'><span>Phone : <strong>+1 850-264-0287</strong></span></p>
                                
                                
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}
export default ContactUs