import React from 'react'
import axiosConfig from '../../axiosConfig'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const initCredentials = {
    email: "",
    password: "",
}


const Login = ({query,setAuthToken,setisAuthenticate}) => {

    const [Credentials, setCredentials] = useState(initCredentials)
    const [Errors, setErrors] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [cookeis,setCookie] = useCookies(['fduat']);
    const navigate =useNavigate();

    const handleValidation = () => {
        let isFormValid = true;
        var error = {}
        if (!Credentials?.email || Credentials?.email === 'undefined') {
            isFormValid = false;
            error['email'] = "Required!"
        }
        if (!Credentials?.password || Credentials?.password === 'undefined') {
            isFormValid = false;
            error['password'] = "Required!"
        }
        setErrors(error)
        return isFormValid
    }

    const handleChange = e => {
        const name = e.target.name
        const value = e.target.value

        setCredentials({
            ...Credentials,
            [name]: value
        })
    }

    const Login = (credentials) => {
        axiosConfig.post('login',credentials)
            .then(res=>{
                if (res.data.success) {
                    setCookie('fduat',res.data.token,{
                        path: `${process.env.REACT_APP_PUBLIC_URL}`,
                        expires : new Date(Date.now()+(30*86400000)),
                    })
                    setisAuthenticate(true)
                    localStorage.setItem('AuthUser', JSON.stringify(res.data.user));
                    setAuthToken(res.data.token)
                    navigate('/')
                   
                }else{
                    setErrors({
                        message: res.data.message
                    })
                }
            })
            .catch(err=>{
                if(!err.response.data.success){
                    setErrors({
                        'message' : err.response.data.message
                    })
                }
            })
    }


    const handleSubmit = e => {
        e.preventDefault();
        if (handleValidation()) {
            Login(Credentials);
        }
    }

    return (
        <div className='container'>
        <div class="fd-lo-fo-ban">
        <div className='fd-lo-fo-sub-ban'>
        <div class="d-grid justify-content-center">
        <img src={"image/fleekdealz-logo-2.png"} alt="" className="img-fluid fd-lo-fo-img" width="180px" />
        </div>
        <h2 className='mb-2'>
            Welcome
        </h2>
        {("message" in Errors)&&<p className=''>{Errors.message}</p>}
        <form className='' onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                <input 
                    type="email" 
                    name='email'
                    className="form-control"
                    id="exampleInputEmail1" 
                    placeholder="Enter Email" 
                    aria-describedby="emailHelp"
                    value={Credentials?.email} 
                    onChange={handleChange} 
                />
                {/* <i class="fas fa-user-tie "></i> */}
                <i class="fas fa-envelope fd-lo-fo-ic"></i>
                {("email" in Errors)?
                <span style={{ color: "red" }}>{Errors.email}</span>
                :<></>
                }
                <label htmlFor="exampleInputEmail1" className="form-label">Password</label>
                <input 
                    type={showPassword?'text':"password"}
                    name='password'
                    className="form-control"
                    placeholder="Enter Password" 
                    value={Credentials?.password} 
                    onChange={handleChange} 
                />
                {/* <i class="fas fa-user-tie "></i> */}
                {!showPassword?
                    <i class="fas fa-eye fd-lo-fo-ic" onClick={()=>setShowPassword(true)}></i>
                :<i class="fas fa-eye-slash fd-lo-fo-ic" onClick={()=>setShowPassword(false)}></i>}
                {("password" in Errors)?
                <span style={{ color: "red" }}>{Errors.email}</span>
                :<></>
                }
                <div  class="form-text fr-tx-fp text-center end-0"><Link to="/forget-password" class="fr-tx-fp"><small>Forget Password</small></Link></div>
                <div  class="form-text fr-tx-fp text-center end-0"><Link to="/" class="fr-tx-fp"><small><i class="fas fa-arrow-left"></i> Back to Home</small></Link></div>

            </div>
            {(Credentials?.email&&Credentials?.password) ?
                <button type="submit" className="fd-lo-fo-ban-form-button btn btn-primary ">Login</button>
                : <button type="button" className="fd-lo-fo-ban-form-button btn btn-primary" disabled>Login</button>
            }
        </form>
        </div>
    </div>
    </div>
    )
}

export default Login