//axios congigration
import axiosConfig from '../axiosConfig';

// Components 
import Filter from './Filter'
import Mobile from './Responsive_utilies/Mobile.js'
import Tablet from './Responsive_utilies/Tablet.js'
import Desktop from './Responsive_utilies/Desktop.js'

// library and packages
import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactPaginate from 'react-paginate'
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductCard from './ProductCard';


const Main = (props) => {

    const { title,
        Loading,
        Error,
        isProduct,
        Product,
        search,
        Keyword,
        keyword,
        appliedFilter,
        Pages,
        searchWord,
        filters,
        Dummyfilter,
        sort,
        Cart,
        CartProduct
    } = props //Global variables of useState

    const { setLoading,
        setError,
        setisProduct,
        setSort,
        setDummyfilter,
        setfilter,
        setProduct,
        setPages,
        setKeyword,
        setsearch,
        setSearchWord,
        setappliedFilter,
        SearchProduct,
        setSelectedkeyword,
        setCart,
        setCartProduct
    } = props //Global func. of useState Variables

    const navigate = useNavigate()

    const [sorted, setSorted] = useState()
    const filtersidebar = useRef(null)
    const [isDesktop, setDesktop] = useState(true)
    const productsLimit = Pages.total_products > 120 ? 120 : Pages.total_products
    const initPage = Pages.current_page ? Pages.current_page - 1 : 0
    const PageCount = Pages.no_of_page > 200 ? 200 : Pages.no_of_page

    const [suggestion, setsuggestion] = useState([])

    let url = useLocation();


    useEffect(() => {

        if (!isProduct) {
            setsuggestion(
                Keyword.length > 0 ?
                    Keyword
                    : [
                        {
                            _id: 1,
                            keyword: 'Health'
                        },
                        {
                            _id: 2,
                            keyword: 'Beauty'
                        },
                        {
                            _id: 3,
                            keyword: 'Hair color'
                        },
                        {
                            _id: 4,
                            keyword: 'Skin Care'
                        },
                    ])
        }
        return () => {
            setsuggestion([])
        }
    }, [isProduct])

    // console.log(suggestion)
    // console.log()
    /***** Functions *****/

    const toggle_visibility = () => {
        if (getComputedStyle(filtersidebar.current).position == 'fixed') {
            if (getComputedStyle(filtersidebar.current).display == 'block') {
                filtersidebar.current.style.display = 'none';
            }
            else {
                filtersidebar.current.style.display = 'block'
            }
        }
    }
    const closeMenu = () => {
        // console.log()
        // window.addEventListener('DOMContentLoaded', () => {
        if (filtersidebar.current) {
            if (getComputedStyle(filtersidebar.current) !== null) {
                if (getComputedStyle(filtersidebar.current).position == 'fixed') {
                    if (getComputedStyle(filtersidebar.current).display == 'block') {
                        filtersidebar.current.style.display = 'none';
                    }
                }
            }
        }
        // });
    }

    const GetqueryString = () => {
        var queryString = "/?"
        var navLink = `${process.env.REACT_APP_PUBLIC_URL}`
        if (keyword) {
            queryString = queryString + `search=${escape(keyword)}`
            navLink = `/search/${keyword.replaceAll("%20", "+")}`
            // setSearchWord(searchWord)
            // console.log(keyword)
        }
        if ((
            appliedFilter.seller.length !== 0 ||
            appliedFilter.size.length !== 0 ||
            appliedFilter.price !== '' ||
            appliedFilter.brand.length !== 0 ||
            appliedFilter.category.length !== 0 ||
            appliedFilter.onsale ||
            appliedFilter.freeShipping
        )) {
            var filterQuerystring = []
            appliedFilter.seller && appliedFilter.seller.length !== 0 && filterQuerystring.push('filter[seller]=' + escape(appliedFilter.seller))
            appliedFilter.brand && appliedFilter.brand.length !== 0 && filterQuerystring.push('filter[brand]=' + appliedFilter.brand)
            appliedFilter.category && appliedFilter.category.length !== 0 && filterQuerystring.push('filter[category]=' + appliedFilter.category)
            appliedFilter.price && filterQuerystring.push('filter[price]=' + appliedFilter.price)
            appliedFilter.onsale && filterQuerystring.push('filter[onSale]=' + 1)
            appliedFilter.freeShipping && filterQuerystring.push('filter[freeShipping]=' + 1)
            appliedFilter.size && appliedFilter.size.length !== 0 && filterQuerystring.push('filter[size]=' + appliedFilter.size)

            if (queryString.length > 2) {
                // queryString = queryString + `&filter[seller]=${escape(appliedFilter.seller)}&filter[price]=${appliedFilter.price}&filter[size]=${appliedFilter.size}&filter[brand]=${appliedFilter.brand}&filter[freeShipping]=${appliedFilter.freeShipping}&filter[onsale]=${appliedFilter.onsale}`
                queryString = queryString + '&' + filterQuerystring.join('&')
            } else {
                // queryString = queryString + `filter[seller]=${escape(appliedFilter.seller)}&filter[price]=${appliedFilter.price}&filter[size]=${appliedFilter.size}&filter[brand]=${appliedFilter.brand}&filter[freeShipping]=${appliedFilter.freeShipping}&filter[onsale]=${appliedFilter.onsale}`
                queryString = queryString + filterQuerystring.join('&')
            }

            navLink = navLink + '?' + filterQuerystring.join('&')

        }
        if ((Pages.current_page && Pages.current_page !== 1)) {
            if (queryString.length > 2) {
                queryString = queryString + `&page=${Pages.current_page}`
            } else {
                queryString = queryString + `page=${Pages.current_page}`
            }
            if (navLink.length === 1 || (navLink.includes('search') && !navLink.includes('filter'))) {
                navLink = navLink + `?page=${Pages.current_page}`
            } else {
                navLink = navLink + `&page=${Pages.current_page}`
            }
        }
        if (sort) {
            if (queryString.length > 2) {
                queryString = queryString + `&sort=${sort}`
            } else {
                queryString = queryString + `sort=${sort}`
            }
            if (navLink.length === 1 || (navLink.includes('search') && !navLink.includes('filter') && !navLink.includes('page'))) {
                navLink = navLink + `?sort=${sort}`
            } else {
                navLink = navLink + `&sort=${sort}`
            }
        }

        navigate(navLink + url.hash)
        return queryString
    }
    useEffect(() => {
        const getProduct = async () => {
            await axiosConfig.post('product')
                .then(res => {
                    if (res.data.success) {
                        if (res.data.status == 200) {
                            setPages({
                                ...Pages,
                                no_of_page: res.data.data.lastpages,
                                total_products: res.data.data.TotalProduct
                            })
                            setfilter({
                                // ...filter,
                                onsale: res.data.data.isOnsale,
                                freeShipping: res.data.data.freeShipping,
                                seller: res.data.data.filter[0].seller,
                                brand: res.data.data.filter[0].brand,
                                size: res.data.data.filter[0].size,
                                category: res.data.data.category,
                                price: res.data.data.price,
                            })
                            setDummyfilter({
                                onsale: res.data.data.isOnsale,
                                freeShipping: res.data.data.freeShipping,
                                seller: res.data.data.filter[0].seller,
                                brand: res.data.data.filter[0].brand,
                                size: res.data.data.filter[0].size,
                                category: res.data.data.category,
                                price: res.data.data.price,
                            })
                            setError()
                        }
                        else {
                            setKeyword(res.data.keyword)
                        }
                    } else {
                        setError(res.data.Error)
                    }
                })
        }
        
        if (!Product) {
            getProduct()
            //console.log("HELLO8669")
        }
        return (() => {

        })
    }, [])

    useEffect(() => {

        const getProduct = async () => {
            var url = ''
            var queryString = GetqueryString()
            if(keyword){
                setSearchWord(keyword.replaceAll('+'," "))
            }
            // console.log(keyword,queryString)
            if (isDesktop) {
                setLoading(true)
            }

            if (queryString.length > 2) {
                url = `product${queryString}`
            }
            else {
                url = 'product'
            }
            await axiosConfig.post(url)
                .then(res => {
                    if (res.data.success) {
                        if (res.data.status == 200) {
                            if (Product?.length < productsLimit && queryString.includes('page') && !isDesktop) {
                                setProduct(Product.concat(res.data.data.product))
                            } else {
                                setProduct(res.data.data.product)
                            }
                            if (!queryString.includes('page')) {
                                // window.scrollTo(0,0)
                                setPages({
                                    no_of_page: res.data.data.lastpages,
                                    current_page: res.data.data.current_page,
                                    total_products: res.data.data.TotalProduct
                                })
                            }
                            if (!queryString.includes('filter')) {
                                setfilter({
                                    // ...filter,
                                    onsale: res.data.data.isOnsale,
                                    freeShipping: res.data.data.freeShipping,
                                    seller: res.data.data.filter[0].seller,
                                    brand: res.data.data.filter[0].brand,
                                    size: res.data.data.filter[0].size,
                                    category: res.data.data.category,
                                    price: res.data.data.price,
                                })
                                setDummyfilter({
                                    onsale: res.data.data.isOnsale,
                                    freeShipping: res.data.data.freeShipping,
                                    seller: res.data.data.filter[0].seller,
                                    brand: res.data.data.filter[0].brand,
                                    size: res.data.data.filter[0].size,
                                    category: res.data.data.category,
                                    price: res.data.data.price,
                                })
                            }
                            // console.log(queryString.indexOf('filter[brand]'))
                            if (queryString.indexOf('filter[brand]') != -1 || queryString.indexOf('filter[seller]') != -1 || queryString.indexOf('filter[category]') != -1) {
                                setfilter({
                                    ...filters,
                                    onsale: res.data.data.isOnsale,
                                    freeShipping: res.data.data.freeShipping,
                                    size: res.data.data.filter[0].size,
                                    price: res.data.data.price,
                                })
                                setDummyfilter({
                                    ...Dummyfilter,
                                    onsale: res.data.data.isOnsale,
                                    freeShipping: res.data.data.freeShipping,
                                    size: res.data.data.filter[0].size,
                                    price: res.data.data.price,
                                })
                                // console.log(Dummyfilter);
                                // console.log(filters);
                            }
                            setisProduct(true)
                        }
                        else {
                            //console.log(res.data.keyword)
                            setisProduct(false)
                            setKeyword(res.data.keyword)
                        }
                        setError()
                    } else {
                        setisProduct(false)
                        setLoading(true)
                        setError(res.data.message)
                    }
                })
                .catch(err => {
                    setError(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        getProduct()
        closeMenu()
    }, [appliedFilter, sort, Pages.current_page,url.pathname])

    function countProductClick(e, fid, pid) {
        axiosConfig.post(`product/Clk`, {
            fid: fid.$oid,
            pid: pid.$oid
        })
    }

    const loadMore = () => {
        const nextPage = parseInt(Pages.current_page) + 1
        setPages({ ...Pages, current_page: nextPage })
        window.scrollTo(0, 0)
    }

    function* chunks(arr, n) {
        for (let i = 0; i < arr?.length; i += n) {
            yield arr?.slice(i, i + n);
        }
    }

    const handleSuggetion = e => {
        setappliedFilter({
            freeShipping: false,
            onsale: false,
            seller: [],
            size: [],
            category: [],
            brand: [],
            price: ''
        })
        setPages({ ...Pages, current_page: 1 })
        setSort()
        setSorted()
        setSearchWord(e.target.innerText)

    }

    const handleSort = (flag) => {
        if (flag) {
            setSort(-1)
            setSorted('Price: High to Low')
        } else {
            setSort(1)
            setSorted('Price: Low to High')
        }
    }

    const clearFilter = () => {
        // console.log("hello");
        setLoading(false)
        setappliedFilter({
            freeShipping: false,
            onsale: false,
            seller: [],
            size: [],
            brand: [],
            category: [],
            price: ''
        })
        setPages({ ...Pages, current_page: 1 })
        setSort()
        setSorted()
        // setsearchWord('');
        setSearchWord('')
        setSelectedkeyword('')
        // console.log('hello')
        navigate(`${process.env.REACT_APP_PUBLIC_URL}`)
        closeMenu()
    }

    const fetchMoreProducts = () => {
        setDesktop(false)
        const nextPage = parseInt(Pages.current_page) + 1
        setPages({ ...Pages, current_page: nextPage })
    }



    const handlePageClick = e => {
        setDesktop(true)
        // const nextPage = e.nextSelectedPage+1
        setLoading(true)
        setPages({ ...Pages, current_page: e.nextSelectedPage + 1 })
    }
   

    return (
        <>
            <Helmet>
                <title>{keyword ? keyword.replaceAll("+", " ") + ' | ' + title : title}</title>
                <meta name="description" content="fleekDeals Search Home Page" />
                <meta name="theme-color" content="#E6E6FA" />
            </Helmet>
            <div>
                <div className="ghd-cate-banner">
                    
                    <div className="d-md-flex justify-content-md-between align-items-md-center mx-md-5 fd-sub-header p-1">
                        <p>
                            {/* <span>FleekDealz</span> */}
                            {searchWord &&
                                <>
                                    <i className="fa-solid fa-angle-right ghd-cate-icons"></i><span>{searchWord}</span>
                                </>
                            }
                            {appliedFilter.price &&
                                <button className='d-inline border border-secondary rounded-pill fd-filter-sidebar-btn ms-3 bg-transparent'
                                    onClick={() => {
                                        setappliedFilter({ ...appliedFilter, price: "" })
                                    }}>
                                    <span className='p-2'>${appliedFilter.price}</span>
                                    <span className='p-2 ps-0 '>X</span>
                                </button>
                            }
                            {appliedFilter.seller.length !== 0 &&
                                appliedFilter.seller.map(seller => {
                                    return (
                                        <button key={seller} className='d-inline border border-secondary rounded-pill ms-3 bg-transparent'
                                            onClick={() => {
                                                setappliedFilter({
                                                    ...appliedFilter,
                                                    seller: appliedFilter.seller.filter(event => (event !== seller))
                                                })
                                            }}>
                                            <span className='p-2'>{seller}</span>
                                            <span className='p-2 ps-0'>X</span>
                                        </button>
                                    )
                                })
                            }
                            {appliedFilter.brand.length !== 0 &&
                                appliedFilter.brand.map(brand => {
                                    return (
                                        <button key={brand} className='d-inline border border-secondary rounded-pill ms-3 bg-transparent'
                                            onClick={() => {
                                                setappliedFilter({
                                                    ...appliedFilter,
                                                    brand: appliedFilter.brand.filter(event => (event !== brand))
                                                })
                                            }}>
                                            <span className='p-2'>{brand}</span>
                                            <span className='p-2 ps-0'>X</span>
                                        </button>
                                    )
                                })
                            }
                            {appliedFilter.category?.length !== 0 &&
                                appliedFilter.category.map(category => {
                                    return (
                                        <button key={category} className='d-inline border border-secondary rounded-pill ms-3 bg-transparent'
                                            onClick={() => {
                                                setappliedFilter({
                                                    ...appliedFilter,
                                                    category: appliedFilter.category.filter(event => (event !== category))
                                                })
                                            }}>
                                            <span className='p-2'>{category}</span>
                                            <span className='p-2 ps-0'>X</span>
                                        </button>
                                    )
                                })
                            }
                            {appliedFilter.size.length !== 0 &&
                                appliedFilter.size.map(size => {
                                    return (
                                        <button key={size} className='d-inline border border-secondary rounded-pill ms-3 bg-transparent'
                                            onClick={() => {
                                                setappliedFilter({
                                                    ...appliedFilter,
                                                    size: appliedFilter.size.filter(event => (event !== size))
                                                })
                                            }}>
                                            <span className='p-2'>{size}</span>
                                            <span className='p-2 ps-0'>X</span>
                                        </button>
                                    )
                                })
                            }
                            {appliedFilter.freeShipping ?
                                <>
                                    <button className='d-inline border border-secondary rounded-pill ms-3 bg-transparent'
                                        onClick={() => {
                                            setappliedFilter({
                                                ...appliedFilter,
                                                freeShipping: false
                                            })
                                        }}>
                                        <span className='p-2'>Free Shpping</span>
                                        <span className='p-2 ps-0'>X</span>
                                    </button>
                                </>
                                : <></>
                            }
                            {appliedFilter.onsale ?
                                <>
                                    <button className='d-inline border border-secondary rounded-pill ms-3 bg-transparent'
                                        onClick={() => {
                                            setappliedFilter({
                                                ...appliedFilter,
                                                onsale: false
                                            })
                                        }}>
                                        <span className='p-2'>on sale</span>
                                        <span className='p-2 ps-0'>X</span>
                                    </button>
                                </> : <></>
                            }
                            {sorted ?
                                <>
                                    <button className='d-inline border border-secondary rounded-pill ms-3 bg-transparent'
                                        onClick={() => {
                                            setSorted()
                                            setSort()
                                        }}>
                                        <span className='p-2'>{sorted}</span>
                                        <span className='p-2 ps-0'>X</span>
                                    </button>
                                </> : <></>
                            }
                            {appliedFilter.seller.length > 0 | appliedFilter.brand.length > 0 | appliedFilter.size.length > 0 | appliedFilter.category.length > 0 | appliedFilter.price !== '' | appliedFilter.freeShipping | appliedFilter.onsale | searchWord.length > 0 | sorted ?
                                <button className='d-inline border border-secondary rounded-pill ms-3 bg-transparent' onClick={clearFilter}>
                                    <span className='btn-link p-2'>Clear all</span>

                                </button>
                                : <></>
                            }
                        </p>
                        {!Error &&
                            <>
                                <div className="dropdown mb-3 px-3 fd-sort-btn">
                                    <button type="button" className="btn dropdown-toggle fd-db df-header-sort" data-bs-toggle="dropdown">
                                        Sort By
                                    </button>
                                    <ul className="dropdown-menu fd-sort-btn-li-hi">
                                        <li><Link to={''} className="dropdown-item" onClick={e=>handleSort(1)}>Price: High to Low</Link></li>
                                        <li><Link to={''} className="dropdown-item" onClick={e=>handleSort(0)}>Price: Low to High</Link></li>
                                        {/* <li><Link className="dropdown-item" to="#"> </Link></li>
                            <li>
                            <hr className="dropdown-divider" />
                            </li>
                            <li><Link className="dropdown-item" to="#">Another link</Link></li> */}
                                    </ul>
                                </div>
                                {/* <i class="fa fa-list-ul df-fa-icon" aria-hidden="true" ></i> */}
                                <Link to={''} className="sidebar-mobile font-16 font-weight-600" onClick={() => { toggle_visibility() }} ><i class="far fa-sliders-h df-fa-filter-icon"></i></Link>
                            </>
                        }

                    </div>
                </div>
                <div className='fd-container'>
                    <img src="/image/special_sale.jpg" className='img-fluid'/>
                </div>
                <div className="mt-md-5 mx-md-5 mx-3">
                    <div className='container'>

                    </div>
                    <div className="row">
                        {!Error &&
                            <Filter
                                filters={filters}
                                Dummyfilter={Dummyfilter}
                                setDummyfilter={setDummyfilter}
                                setProduct={setProduct}
                                setLoading={setLoading}
                                setError={setError}
                                Pages={Pages}
                                searchWord={searchWord}
                                setPages={setPages}
                                setappliedFilter={setappliedFilter}
                                appliedFilter={appliedFilter}
                                SearchProduct={SearchProduct}
                                setfilter={setfilter}
                                filtersidebar={filtersidebar}
                                closeMenu={closeMenu}
                            />
                        }
                        <div className={Error ? 'col-md-12' : 'col-md-9'}>
                            {!Loading ?
                                <>
                                    {!isProduct &&
                                        <div className=' mt-0 fd-suggestion-banner'>
                                            <p className='fs-5'>Suggestion</p>
                                            <ul>
                                                {Error && <li className='fs-6 ms-2 fw-100'>Make sure that all words are spelled correctly .</li>}
                                                {/* {!Error && <li className='fs-6 ms-2 fw-100'>Try different fillter.</li>}
                                            <li className='fs-6 ms-2 fw-100'>Try more general keywords .</li>
                                            <li className='fs-6 ms-2 fw-100'>Try different keywords.</li> */}
                                            </ul>
                                            <div className=' '>
                                                <p className='fs-5 text-danger m-2'>Did you mean: </p>

                                                <div className='m-2 ms-0'>
                                                    {
                                                        suggestion.slice(0, 4).map(item => {
                                                            return <Link to={''} className='fs-5 ms-2 fd-suggetion' key={item._id} onClick={handleSuggetion}>{item.keyword}</Link>
                                                        })
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <Mobile>
                                        {isProduct && Product?.length ?
                                            <InfiniteScroll
                                                dataLength={Product?.length} //This is important field to render the next data
                                                next={fetchMoreProducts}
                                                hasMore={Product?.length !== productsLimit}
                                                // hasMore={false}
                                                loader={
                                                    <div class="fd-ld-all-wrapper">
                                                    <div class="fd-ld-all-dots">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div></div>
                                                }
                                                endMessage={
                                                    <div className='d-flex justify-content-between'>
                                                        <button
                                                            className='btn-sm btn-secondary df-bc-pr-btn'
                                                            disabled={Pages.current_page < 10}
                                                            onClick={() => {
                                                                setDesktop(false)
                                                                setProduct([])
                                                                const prev = parseInt(Pages.current_page) - 10
                                                                setPages({ ...Pages, current_page: prev })
                                                            }}
                                                        >Back</button>
                                                        <button
                                                            className='btn-sm btn-secondary df-bc-pr-btn'
                                                            // disabled={productsLimit} 
                                                            onClick={() => {
                                                                setDesktop(false)
                                                                setProduct([])
                                                                const nextPage = parseInt(Pages.current_page) + 1
                                                                setPages({ ...Pages, current_page: nextPage })
                                                            }}
                                                        >More</button>
                                                    </div>
                                                }
                                            >
                                                <div className="row pdct_row" >
                                                    <ProductCard
                                                        className={`${Error ? 'col-md-2' : 'col-md-3'} col-6 pdct_column`}
                                                        Error={Error}
                                                        Product={Product}
                                                        Cart={Cart}
                                                        CartProduct={CartProduct}
                                                        setCart={setCart}
                                                        setCartProduct={setCartProduct}
                                                    />
                                                </div>
                                            </InfiniteScroll>
                                            : <div>No Products</div>
                                        }
                                    </Mobile>
                                    <Tablet>
                                        {isProduct && Product?.length ?
                                            <InfiniteScroll
                                                dataLength={Product?.length} //This is important field to render the next data
                                                next={fetchMoreProducts}
                                                hasMore={Product?.length !== productsLimit}
                                                // hasMore={false}
                                                loader={
                                                    <div class="fd-ld-all-wrapper">
                                <div class="fd-ld-all-dots">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div></div>
                                                }
                                                endMessage={
                                                    <div className='d-flex justify-content-between'>
                                                        <button
                                                            className='btn-sm btn-secondary df-bc-pr-btn'
                                                            disabled={Pages.current_page < 10}
                                                            onClick={() => {
                                                                setDesktop(false)
                                                                setProduct([])
                                                                const prev = parseInt(Pages.current_page) - 10
                                                                setPages({ ...Pages, current_page: prev })
                                                            }}
                                                        >Back</button>
                                                        <button
                                                            className='btn-sm btn-secondary df-bc-pr-btn'
                                                            // disabled={productsLimit} 
                                                            onClick={() => {
                                                                setDesktop(false)
                                                                setProduct([])
                                                                const nextPage = parseInt(Pages.current_page) + 1
                                                                setPages({ ...Pages, current_page: nextPage })
                                                            }}
                                                        >More</button>
                                                    </div>
                                                }
                                            >
                                                <div className="row pdct_row" >
                                                    <ProductCard
                                                        className={`${Error ? 'col-md-2' : 'col-md-3'} col-6 pdct_column`}
                                                        Error={Error}
                                                        Product={Product}
                                                        Cart={Cart}
                                                        CartProduct={CartProduct}
                                                        setCart={setCart}
                                                        setCartProduct={setCartProduct}
                                                    />
                                                </div>
                                            </InfiniteScroll>
                                            : <div>No Products</div>
                                        }
                                    </Tablet>
                                    <Desktop>
                                        <div className="row pdct_row" >
                                            <ProductCard
                                                className={`${Error ? 'col-md-2' : 'col-md-3'} col-6 pdct_column`}
                                                Error={Error}
                                                Product={Product}
                                                Cart={Cart}
                                                CartProduct={CartProduct}
                                                setCart={setCart}
                                                setCartProduct={setCartProduct} />
                                        </div>
                                        {(!Error && PageCount > 1 && isProduct) &&
                                            <ReactPaginate
                                                initialPage={initPage}
                                                pageCount={PageCount}
                                                breakLabel="..."
                                                nextLabel="next >"
                                                className="pagination"
                                                onPageChange={() => { window.scrollTo(0, 0) }}
                                                pageRangeDisplayed={5}
                                                onClick={(e) => handlePageClick(e)}
                                                previousLabel="< previous"
                                                activeClassName={'active'}
                                            />
                                        }
                                    </Desktop>
                                </>
                                :
                                <div class="fd-ld-all-wrapper">
                                <div class="fd-ld-all-dots">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main