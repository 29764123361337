import { useState } from "react"
import React from 'react'
import { useNavigate } from "react-router-dom"

const PlaceOrder = (props) => {

    const {UserDetails , setUserDetails , placeOrder , IsLoading , setIsLoading , setStep} = props
    const [Errors, setErrors] = useState({})
    const navigate = useNavigate()

    function dateFormate(value) {
        if(value.length <= 2){
            return value;
           }
        else{
            if (!value.includes('/')) {
                return value.substr(0, 2) + "/" + (value.substr(2) || "")
            }
            return value;
        }

    }

    function dabitCardFormate(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []
        for (let i=0;i<match.length; i+=4) {
            parts.push(match.substring(i, i+4))
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }

    const handleValidation = () => {
        let isFormValid = true;
        var error = {}
        if (!UserDetails.CardNumber||UserDetails.CardNumber==='undefined') {
            isFormValid = false;
            error['CardNumber'] = "Required!"
        }
        if (!UserDetails.Expiry_dt||!UserDetails.Expiry_dt==='undefined') {
            isFormValid = false;
            error['Expiry_dt'] = "Required!"
        }
        if (!UserDetails.Cvv||!UserDetails.Cvv==='undefined') {
            isFormValid = false;
            error['Cvv'] = "Required!"
        }
        if(UserDetails.CardNumber)
        {
            if(UserDetails.CardNumber.length<16)
            {
                isFormValid = false;
                error['CardNumber'] = "Invalid!*"
            }
        }
        if (UserDetails.Cvv) {
            if (UserDetails.Cvv.length<3) {
                isFormValid = false;
                error['Cvv'] = "Invalid!" 
            }
            
        }

        setErrors(error)
        return isFormValid
    }


    const handleSubmit = e =>{
        e.preventDefault();
        if(handleValidation()){
            placeOrder();
            setErrors({});
        }
    }
    // console.log(IsLoading)

    return (
        <>
            <h3>Place Order</h3>
            {/* <div className='container-full'> */}
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <div className="card m-1">
                        <div className="card-body">
                            <h6 className="card-subtitle text-secondary mb-2 text-muted">Personal Details</h6>
                            <p className="card-text m-0">Name  :    <span className=''>{UserDetails.first_name} {UserDetails.last_name}</span></p>
                            <p className="card-text m-0">Email :    <span className=''>{UserDetails.email}</span></p>
                            {UserDetails.phone&&<p className="card-text m-0">Phone :    <span className='text-secondary'>{UserDetails.phone}</span></p>}
                        </div>
                    </div>
                    <div className="card m-1">
                        <div className="card-body">
                            <div className='d-flex justify-content-between align-content-center'>
                                    <h6 className="text-muted">Billing Details</h6>
                                    <button className="btn text-secondary m-0 p-0 fs-6" onClick={()=>{navigate('/check-out?step=2')}}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                </div>
                            <p className="card-text m-0">
                                <span className='text-secondary'>{UserDetails.BillingDetail.Address1}{UserDetails.BillingDetail.Address2&&' , '+UserDetails.BillingDetail.Address2}</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.BillingDetail.City},{UserDetails.BillingDetail.State},{UserDetails.BillingDetail.Zip}</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.BillingDetail.Country}</span>
                            </p>
                        </div>
                    </div>
                    <div className="card m-1">
                        <div className="card-body">
                                <div className='d-flex justify-content-between align-content-center'>
                                    <h6 className="text-muted">Shipping Details</h6>
                                    <button className="btn text-secondary m-0 p-0 fs-6" onClick={()=>{navigate('/check-out?step=2')}} ><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                </div>
                            {!UserDetails.isShiptoDiffAdd?
                            <>
                            <p className="card-text m-0">
                                <span className='text-secondary'>{UserDetails.first_name} {UserDetails.last_name},</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.BillingDetail.Address1} {UserDetails.BillingDetail.Address2&&UserDetails.BillingDetail.Address2}</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.BillingDetail.City},{UserDetails.BillingDetail.State},{UserDetails.BillingDetail.Zip}</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.BillingDetail.Country}</span>
                            </p>
                            </>
                            :
                            <>
                            <p className="card-text m-0">
                                <span className='text-secondary'>{UserDetails.ShippingDetail.first_name} {UserDetails.ShippingDetail.last_name}</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.BillingDetail.Address1} {UserDetails.ShippingDetail.Address2&&UserDetails.ShippingDetail.Address2}</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.ShippingDetail.City},{UserDetails.ShippingDetail.State} ,{UserDetails.ShippingDetail.Zip}</span>
                                <br/>
                                <span className='text-secondary'>{UserDetails.BillingDetail.Country}</span>
                            </p>
                            </>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className="card border-0">
                        <div className="card-header d-flex justify-content-between">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioCheckedDisabled" checked disabled />
                                <h5>
                                    Credit Card
                                </h5>
                            </div>
                            <div>
                                <img className='ms-1' src={'/image/discover.svg'} width={40} />
                                <img className='ms-1' src={'/image/amex.svg'} width={40} />
                                <img className='ms-1' src={'/image/mastercard.svg'} width={40} />
                                <img className='ms-1' src={'/image/visa.svg'} width={40} />
                            </div>
                        </div>
                        <div className="card-body">
                            <p>Pay with your credit card via Authorize.Net.</p>
                            <div>
                                <form className="row g-3 p-2" onSubmit={handleSubmit}>
                                    <div className="col-12">
                                        <label htmlFor="CardNumber" className="form-label">Card Number*</label>
                                        <input type="text" className="form-control" id="CardNumber" placeholder="Enter Card Number" value={UserDetails.CardNumber} onChange={e=>{(e.target.value.length<120&&!isNaN(e.target.value.replaceAll(' ','')))&&setUserDetails({...UserDetails,CardNumber:dabitCardFormate(e.target.value)})}} />
                                        {("CardNumber" in Errors)?
                                        <span style={{ color: "red" }}>{Errors.CardNumber}</span>
                                        :<></>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="expiry_dt" className="form-label">Expiry(MM/YYYY)*</label>
                                        <input type="text" className="form-control" id="expiry_dt" placeholder="MM/YYYY" value={UserDetails.Expiry_dt} onChange={e=>{(e.target.value.length<8&&!isNaN(e.target.value.replace('/','')))&&setUserDetails({...UserDetails,Expiry_dt: dateFormate(e.target.value)})}} />
                                        {("Expiry_dt" in Errors)?
                                        <span style={{ color: "red" }}>{Errors.Expiry_dt}</span>
                                        :<></>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="cvv" className="form-label">Card Code*</label>
                                        <input type="number" className="form-control" id="cvv" placeholder="CVC" value={UserDetails.Cvv} onChange={e=>{e.target.value.length<5&&setUserDetails({...UserDetails,Cvv:e.target.value})}} />
                                        {("Cvv" in Errors)?
                                        <span style={{ color: "red" }}>{Errors.Cvv}</span>
                                        :<></>
                                        }
                                    </div>
                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="button" onClick={(e)=>{if(handleValidation()){handleSubmit(e);setIsLoading(true);}}} className="df-check-out-pl-or-btn btn btn-primary w-100" disabled={IsLoading}>
                                            {!IsLoading?
                                                <span>Place Order</span>
                                            :
                                            <div className="spinner-border text-light" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>

    )
}

export default PlaceOrder