import React from "react";
import Breakpoint from './BreakPoint';

export default function Mobile(props) {

    // console.log("HELLO form mobile")
    // props.setBreakpoint("phone")

    return (
        <Breakpoint name="phone" >
        {props.children}
        </Breakpoint>
    );
}