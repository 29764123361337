// import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div className='fd-footer'>
    <div className='container'>
      <div className=" row align-items-center mx-0">
        <div className='col-md-12'>
          <div className=" pe-0  pt-3">
            <p className="">© fleekdealz.com. All rights reserved.</p>

            <p><Link to="/terms-and-conditions" className="">Terms and Conditions</Link></p>
            <p>|</p>
            <p><Link to="/shipping-refund-policy" className="">Shipping and Refund-Policy</Link></p>
            <p>|</p>
            <p><Link to="/privacy-policy" className="">Privacy Policy</Link></p>
            <p>|</p>
            <p><Link to="/contact-us" className="">Contact Us</Link></p>

          </div>
          
        </div>
        <div className='col-md-9 pt-md-3'>
        <div className='container text-left p-1'>
            <p className=""><strong>Address : </strong><span>4513 Wimbleton court,Tallahassee FL 32303.</span></p>

            <p className="ms-md-2"><strong>Email : </strong><span>sales@fleekdealz.com</span></p>
            <p className="ms-md-2"> <a href="https://www.facebook.com/Fleekdealz" target={"_blank"} className=""><i class="fab fa-facebook-square fs-4 "></i></a></p>
          </div>
        </div>
        <div className='col-md-3 pt-3 mb-1'>
          <div>
            <img className='ms-1' src={'/image/discover.svg'} width={50} />
            <img className='ms-1' src={'/image/amex.svg'} width={50} />
            <img className='ms-1' src={'/image/mastercard.svg'} width={50} />
            <img className='ms-1' src={'/image/visa.svg'} width={50} />
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Footer