import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Navigate, useNavigate } from 'react-router-dom'
import axiosConfig from '../../axiosConfig'

const orderInitState = [{
    billingDetails : {},
    line_items : [],
    status : '',
    wc_order_id : '',
    transaction_id : '',
    created_at : ''
}]

const OrdersList = ({ AuthToken }) => {

    const user = JSON.parse(localStorage.getItem('AuthUser'))
    const [Orders, setOrders] = useState(orderInitState)
    const navigate = useNavigate()

    useEffect(() => {
        axiosConfig.get(`/user/getOrders`,{
            params: {
                email:user.email
            },
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + AuthToken
            }
        })
        .then(res => {setOrders(res.data[0])})
        .catch(err => { 
            navigate(`/logout`)
        })
    }, [])

    return (
        <>
            <Helmet>
                <title>Orders | fleekDealz</title>
                <meta name="description" content="fleekDeals" />
                <meta name="theme-color" content="#E6E6FA" />
            </Helmet>
            <div>
                <div className="mt-md-5 mx-md-5 mx-3 fd-container ">
                    <table className="table text-white" >
                        <thead style={{'background':'#4fc3ae'}}>
                            <tr>
                                <th scope="col">Order Id</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(Orders.length>0&&Orders[0].line_items.length>0)&&
                            Orders.map(order=>{
                                return (
                                    <tr style={{'color':'#3f3d3d'}}>
                                        <th scope="row">{order?.id}</th>
                                        <td>{order?.created_at}</td>
                                        <td>{order?.status}</td>
                                        <td>
                                            <button 
                                                className='btn btn-outline-primary' 
                                                onClick={()=>{
                                                    
                                                    navigate(`/order/${order?.id}`)
                                                }}>
                                                view
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })

                            }
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OrdersList