import React from 'react'
import { Helmet } from 'react-helmet'

const ShippingRefund = () => {
  return (
    <>  
        <Helmet>
            <title>FleekDealz</title>
        </Helmet>
        <div className='fd-tc-main-container mx-4'>
        <div className="container">
			<h5>Shipping and Return-Policy</h5>
            <hr/> 
            <p><strong>Delivery Time</strong></p>
                    <p>USA Delivery will take 5-8 business days.</p>
                    <p><strong>Processing Time</strong></p>
                    <p>Almost all orders are processed and shipped out within 1-2 days if the order was received before 12 P.M. CST. Orders are not shipped out on Sundays and will resume the following business day.</p>
                    
                    {/* <p>fleekdealz.com is in no way responsible for any shipment that is not delivered and by purchasing from fleekdealz.com you are agreeing to these terms and hereby forfeit any right to dispute any shipment that has been delayed, lost, stolen, or non-deliverable due to acts out of our control, unless the fault is of fleekdealz.com.</p> */}
                    <p><strong>Return Policy</strong></p>
                    <p>30 Days refund and return Policy</p>
                    <p>All orders can be returned up to 30 days after the ship date for a full refund minus shipping and gift wrap charges. If you would like to return a product from your order simply send the product back to the address below in its original packaging. You can expect a refund within one billing cycle of us receiving your returned product.</p>
                    <p>Shipping cost is non-refundable for undelivered, unclaimed, returned and refused packages, unless we made an error.</p>
                    <p>Also, we understand customers must open items before determining if they like it. As long as the item is not used in excess, you will get a full refund for it. If not, you will be subject to a partial refund.</p>
                    <p><strong>Damage Claims</strong></p>
                    <p>In the unlikely event that your order or part of your order arrives damaged, please email us immediately so we can notify the carrier and file a damage claim. Please keep all packaging materials after notifying us and a replacement will be sent to you.</p>
                    <p><strong>Cancellations</strong></p>
                    <p>In order to provide our customers with the best shopping experience, our systems are automated and therefore orders cannot be cancelled after they are placed. If a cancel option appears next to your order in your order history section then you may cancel your order.</p>
                    <p><strong>Shipping Charges</strong></p>
                    <p>USA orders will be shipped via USPS,UPS,DHL or FedEx and shipping cost  depend on the products type, on the weight of the order,destination and method of delivery. In some products we are giving FREE Delivery as well.</p>
                    <p>Original shipping charges are non-refundable in the case of a returned product. Those fees are paid for by the services provided and will not be refunded to you unless it was fleekdealz.com error.</p>
                    <p><strong>Order Status</strong></p>
                    <p>fleekdealz.com will keep you informed of your order status via e-mail. All tracking information will be emailed to your registered email account once your order has shipped (except when an APO/FPO and international is the shipping destination). You may also acquire your order status by logging in to your fleekdealz.com account on our Web site.</p>
		</div>
        </div>
    </>
  )
}

export default ShippingRefund