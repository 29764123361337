import React from 'react'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axiosConfig from '../../axiosConfig'
import { useCookies } from 'react-cookie'


const ResetPassword = ({query,setAuthToken,setisAuthenticate}) => {

    const [cookeis,setCookie] = useCookies(['fduat']);
    const {token} = useParams()
    const navigate =useNavigate();
    const email = query.get('email') || null

    const [Credentials, setCredentials] = useState({
        email : email,
    })
    const [Errors, setErrors] = useState({})


    const handleChange = e => {
        const name = e.target.name
        const value = e.target.value

        setCredentials({...Credentials,
            [name]: value
        })
    }



    const handleSubmit = e => {
        e.preventDefault();
        setErrors({})
        // console.log(Credentials)
        axiosConfig.post(`reset-password/${token}`,Credentials)
        .then(res=>{
            if (res.data.status == 200) {
                setCookie('fduat',res.data.token,{
                    path: `${process.env.REACT_APP_PUBLIC_URL}`,
                    expires : new Date(Date.now()+(30*86400000)),
                })
                setisAuthenticate(true)
                localStorage.setItem('AuthUser', JSON.stringify(res.data.user));
                setAuthToken(res.data.token)
                navigate('/')
               
            }else{
                setErrors({
                    message: res.data.message
                })
            }
        })
        .catch(err=>{
            setErrors(err.response.data.messages)
            setCredentials({email:email})
        })
        .finally(()=>setCredentials({email:email}))
        setCredentials({email:email})
    }

    return (
        <div className='container'>
            <div class="fd-lo-fo-ban">
            <div className='fd-lo-fo-sub-ban'>
            <div class="d-grid justify-content-center">
            <img src={"image/fleekdealz-logo-2.png"} alt="" className="img-fluid fd-lo-fo-img" width="180px" />
            </div>
            <h2 className='mb-2'>
                Welcome
            </h2>
            <p className=''>{email}</p>
            {("message" in Errors)?
            <p className=''>{Errors.message}</p>
            : <p className=''>Please enter your new credential</p>
            }
            <form className='' onSubmit={handleSubmit}>
                {/* <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                    <input 
                        type="email" 
                        name='email'
                        className="form-control"
                        id="exampleInputEmail1" 
                        placeholder="Enter Email" 
                        aria-describedby="emailHelp"
                        value={Credentials.email} 
                        readOnly 
                    />
                    <i class="fas fa-user-tie "></i>
                    <i class="fas fa-envelope fd-lo-fo-ic"></i>
                    {("email" in Errors)?
                    <span style={{ color: "red" }}>{Errors.email}</span>
                    :<></>
                    }
                </div> */}
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label mt-3">New Password</label>
                    <input type="password"name='password'className="form-control"id="Password" placeholder="........" onChange={handleChange}/>
                    <button 
                        type="button" 
                        class="p-0 m-0 lh-1 fd-lo-fo-ic btn" 
                        >
                    <i 
                        class="fas fa-eye"
                        onClick={(e)=>{
                            if(document.getElementById('Password').type=="password"){
                                document.getElementById('Password').setAttribute('type','text')
                                e.target.className = 'fas fa-eye-slash'
                            }else{
                                document.getElementById('Password').setAttribute('type','password')
                                e.target.className = 'fas fa-eye'  
                            }
                            }
                        } 
                    >
                    </i> 
                    {/* <i class="fas fa-eye-slash"></i> */}
                    </button>
                    {("password" in Errors)?
                    <span style={{ color: "red" }}>{Errors.password}</span>
                    :<></>
                    }
                     {/* <div id="passwordHelp" class="form-text fr-tx-fp"><Link to="/forget-password" class="fr-tx-fp"><small> Forget Password?</small></Link></div> */}
                    {/* <Link to="forget-password" >forget password?</Link> */}
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label mt-3">Confirm Password</label>
                    <input type="password"name='cpassword'className="form-control"id="Confirm-Password" placeholder="........" onChange={handleChange}/>
                    <button 
                        type="button" 
                        class="p-0 m-0 lh-1 fd-lo-fo-ic btn" 
                        >
                    <i 
                        class="fas fa-eye"
                        onClick={(e)=>{
                            if(document.getElementById('Confirm-Password').type=="password"){
                                document.getElementById('Confirm-Password').setAttribute('type','text')
                                e.target.className = 'fas fa-eye-slash'
                            }else{
                                document.getElementById('Confirm-Password').setAttribute('type','password')
                                e.target.className = 'fas fa-eye'  
                            }
                            }
                        } 
                    >
                    </i> 
                    {/* <i class="fas fa-eye-slash"></i> */}
                    </button>
                    {("cpassword" in Errors)?
                    <span style={{ color: "red" }}>{Errors.cpassword}</span>
                    :<></>
                    }
                     {/* <div id="passwordHelp" class="form-text fr-tx-fp"><Link to="/forget-password" class="fr-tx-fp"><small> Forget Password?</small></Link></div> */}
                    {/* <Link to="forget-password" >forget password?</Link> */}
                </div>
                {(Credentials.email&&Credentials.password&&Credentials.cpassword) ?
                    <button type="submit" className="fd-lo-fo-ban-form-button btn btn-primary fs-6">Reset Password</button>
                    : <button type="button" className="fd-lo-fo-ban-form-button btn btn-primary fs-6" disabled>Reset Password</button>
                }
            </form>
            </div>
        </div>
        </div>
    )
}

export default ResetPassword