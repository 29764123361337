import axios from 'axios'


const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL+'/api',
        headers : {
            Authorization : `Bearer ${process.env.REACT_APP_JWT_API}`
        }
    });


export default instance;
